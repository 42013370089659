import storage from "reduxjs-toolkit-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import { TypedUseSelectorHook, useDispatch , useSelector } from "react-redux";
import rootReducer from "./reducers";
/*
  This will be the persisted Reducer, it will detect change in state
  and persists it again
*/
const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
  },
  rootReducer,
);

// Defining Store Now
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

// Defining Hooks for accessing and mutating store
export const useAppDispatch: ()=>typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;

// Finally defining and exporting persistor
export const persistor = persistStore(store);
// exporting store
export default store;
