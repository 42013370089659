import { Typography } from "@mui/material";
const Section4 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 4: Statement and Records
      </Typography>
      <Typography sx={{ mb: 2 }}>
        ONTAB Inc. will issue statements on a monthly basis. The Borrower may
        also request a balance update and summary of outstanding loan amount at
        any time.
      </Typography>
    </div>
  );
};

export default Section4;
