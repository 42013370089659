import { MasterCardLogo, VisaLogo } from "../assets/png";

export const capitalize = (str: string | undefined) => {
  if (str) {
    const arr = str.split(" ");
    for (let i = 0; i < arr.length; i += 1) {
      arr[i] =
        arr[i].length > 0
          ? arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
          : arr[i];
    }
    return arr.join(" ");
  }
  return "";
};

export const listCountryNames: any = {
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Virgin Islands": "VG",
  "British Indian Ocean Territory": "IO",
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  "China (except Hong Kong, Macao)": "CN",
  "Christmas Island": "CX",
  "Cocos Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  "Congo Brazzaville": "CG",
  "Congo Kinshasa": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  "Côte d'Ivoire (Ivory Coast)": "CI",
  Croatia: "HR",
  Cuba: "CU",
  Curacao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "England (United Kingdom)": "ENG",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkan Islands": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard and Mcdonald Islands": "HM",
  "Holy See (Vatican City State)": "VA",
  Honduras: "HN",
  "Hong Kong (China)": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  "Islamic Republic of Iran": "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Kosovo: "XK",
  "North Korea": "KP",
  "South Korea": "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  "Laos (Lao PDR)": "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  "Macao (China)": "MO",
  "Republic of Macedonia": "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  "Federated States of Micronesia": "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "Netherlands Antilles": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "Northern Ireland (United Kingdom)": "NIR",
  "Northern Mariana Islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  Reunion: "RE",
  Romania: "RO",
  "Russian Federation": "RU",
  Rwanda: "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  "Scotland (United Kingdom)": "SCT",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Sudan": "SS",
  "Soviet Union (USSR)": "SU",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen Islands": "SJ",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  "Syrian Arab Republic (Syria)": "SY",
  "Taiwan (Republic of China)": "TW",
  Tajikstan: "TJ",
  "United Republic of Tanzania": "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom (England, Wales, Scotland, Northern Ireland)": "GB",
  "United States of America": "US",
  "US Minor Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Venezuela (Bolivarian Republic)": "VE",
  "Viet Nam": "VN",
  "US Virgin Islands": "VI",
  "Wales (United Kingdom)": "WLS",
  "Wallis and Futuna Islands": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};

// Reverse the listCountryNames object
const countryCodeToName = Object.fromEntries(
  Object
    .entries(listCountryNames)
    .map(([key, value]) => [value, key])
  );

export const provinceSlugs: any = {
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  Nunavut: "NU",
  "Nova Scotia": "NS",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Yukon: "YT",
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

// Reverse mapping of provinceSlugs
export const provinceSlugToProvince = Object.fromEntries(
  Object
    .entries(provinceSlugs)
    .map(([key, value]) => [value, key])
  );

export const getReferalCode = () => {
  const referalCode = localStorage.getItem("referalCode");
  if (referalCode) return JSON.parse(referalCode);
  return "";
};

export const getRequestedAmount = () => {
  const requestedAmount = localStorage.getItem("requestedAmount");
  if (requestedAmount) return JSON.parse(requestedAmount);
  return 100;
};

export const getCaculatedAmount = () => {
  const calculatedAmount = localStorage.getItem("pmt");
  if (calculatedAmount) return JSON.parse(calculatedAmount);
  return 0;
};

export const getNumberOfPayments = () => {
  const numberOfPayments = localStorage.getItem("numberOfPayments");
  if (numberOfPayments) return Number.parseInt(JSON.parse(numberOfPayments));
  return 0;
};

export const getCardLogo = (value: string) => {
  if (value === "visa") {
    return VisaLogo;
  } else if (value === "mastercard") {
    return MasterCardLogo;
  }
};

export const formatCard = (value: string) => {
  return `**** **** **** ${value.substring(value.length - 4, value.length)}`;
};

export const getApr = () => {
  const apr = localStorage.getItem('apr');
  if (apr) return JSON.parse(apr);
  return 0;
}

export const getTotalCost = () => {
  const totalCost = localStorage.getItem('totalCost');
  if (totalCost) return JSON.parse(totalCost as string);
}

export const getInterestCharges = () => {
  const interestCharges = localStorage.getItem('interestCharges');
  if (interestCharges) return JSON.parse(interestCharges as string);
}

export const generatePaymentSchedule = (months:number) => {
  let [startDate, daysInCurrentMonth] = getStartDate(); 
  let schedules = [startDate];
  for (let i = 1; i < months; i++) {
      const nextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
      const daysInNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0).getDate();
      let finalDate;
      
      if (daysInNextMonth <= daysInCurrentMonth) {
          finalDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, daysInNextMonth);
      } else {
          finalDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, daysInCurrentMonth);
      }
      
      startDate = finalDate;
      schedules.push(finalDate);
  }
  
  return schedules;
} 

const getStartDate:any = () => {
  const currentDate = new Date();
  const daysInCurrentMonth = currentDate.getDate();
  let startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const daysInStartDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 0).getDate()
  if (daysInStartDate <= daysInCurrentMonth){
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, daysInStartDate);
      
  }else{
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, daysInCurrentMonth);
  }
  
  return [startDate, daysInCurrentMonth];
}

export const getMonthName = (month:number) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthNames[month];
}

export const clearLocalStorage = () => {
  const refCode = localStorage.getItem('referalCode') || "";
  localStorage.clear();
  localStorage.setItem('referalCode', refCode);
}

export const findCountryFullName = (country: string) => {
  return countryCodeToName[country];
}

export const findProvinceFullName = (province: string) => {
  return provinceSlugToProvince[province];
}

export const getCardHoldAmount = (id: number) => {
  const key = `card_${id}`
  const cardExists = localStorage.getItem(key);
  if (!cardExists) return;
  return JSON.parse(cardExists)?.splitAmount;
}