import { combineReducers } from "@reduxjs/toolkit";
import applicantFormReducer from "./features/Applicant"
import bnplReducer from "./features/bnpl"

const rootReducer = combineReducers({
  "applicant": applicantFormReducer,
  "bnpl": bnplReducer,
});

export default rootReducer;
