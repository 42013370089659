import * as Yup from "yup";

const currentYear = Number.parseInt(
  new Date().getFullYear().toString().substring(2, 4)
);
const currentMonth = new Date().getMonth() + 1;
const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastercardRegEx =  /^5[1-5][0-9]{14}$/;

const CreditCardFormSchema = Yup.object({
  cardholderName: Yup.string().required("Please enter cardholder name!"),
  cardNumber: Yup.string()
    .required("Please enter card number!")
    .test("test-credit-card", "Invalid credit card number", (value) => {
      // Check if the credit card number is valid
      if (!value) return false;
      const val = value.replaceAll(" ", "");
      // const first4Digits = value.substring(0, 4);
      // if (first4Digits.startsWith("4")) {
      //   // Visa card
      //   return /^4[0-9]{12}(?:[0-9]{3})?$/.test(val);
      // }
      // if (
      //   first4Digits === "2221" ||
      //   first4Digits === "2222" ||
      //   first4Digits === "2720"
      // ) {
      //   // Mastercard
      //   return /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/.test(
      //     val
      //   );
      // }
      if(visaRegEx.test(val)) return true;
      if(mastercardRegEx.test(val)) return true;
      return false
    }),

  cvv: Yup.string()
    .required("Please enter card cvc!")
    .test("test-cvc", "Invalid CVC code", (value) => {
      // Check if the CVC code is valid
      if (!value) return false;
      return /^[0-9]{3}$/.test(value);
    }),

  expDateMonth: Yup.string()
    .required("Please enter card expiry!")
    .test("month", (value, ctx) => {
      var parsedMonth = parseInt(value, 10);
      const expYear = parseInt(ctx?.parent?.expDateYear, 10);
      if (parsedMonth > 12 || parsedMonth <= 0)
        return ctx.createError({ message: "Month should be in range 1-12" });
      else {
        if (expYear === currentYear) {
          if (parsedMonth >= currentMonth) return true;
          else
            return ctx.createError({
              message: "Expiration date must be in future.",
            });
        } else if (expYear > currentYear) {
          if (parsedMonth >= 1 && parsedMonth <= 12) return true;
          else
            return ctx.createError({
              message: "Month should be in range 1-12",
            });
        } else {
          return ctx.createError({
            message: "Expiration date must be in future.",
          });
        }
      }
    }),

  expDateYear: Yup.string()
    .required("Please enter card expiry!")
    .matches(/^[0-9]{2}$/, "Invalid expiration year format")
    .test(
      "year",
      "Expiration year must be greater than or equal to current year",
      (value) =>
        parseInt(`20${value}`, 10) > currentYear ||
        (parseInt(`20${value}`, 10) === currentYear &&
          parseInt(value, 10) >= currentMonth)
    ),

  address1: Yup.string().required("Please enter your address!"),
  address2: Yup.string(),
  postalCode: Yup.string()
    .matches(/^[a-zA-Z0-9]{3} [a-zA-Z0-9]{3}$/, "Invalid Postal Code Format")
    .required("Please enter your postal code!"),
  city: Yup.string().required("Please enter your city!"),
  province: Yup.string().required("Please enter your province!"),
  country: Yup.string().required("Please enter your country!"),
  confirmBox: Yup.boolean().oneOf([true]),
});

export default CreditCardFormSchema;
