import { Button } from "@mui/material";

type CircularButtonProps = {
  children: React.ReactNode;
  variant?: "contained" | string;
  [key: string]: any;
};
const CircularButton = (props: CircularButtonProps) => {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        backgroundColor: "ghostwhite",
        color: "#3075FF",
        borderRadius: "50%",
        minWidth: "20px !important",
        padding: "0 !important;",
        width: "40px",
        height: "40px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "ghostwhite",
          color: "#3075FF",
        },
      }}
      type="submit"
    >
      {props.children}
    </Button>
  );
};

export default CircularButton;
