import { ReactElement } from "react";
import { Typography, FormHelperText, Autocomplete, TextField } from "@mui/material";
import { ErrorAnimation } from "../../../animation";
import { makeStyles } from "@material-ui/core";
import theme from "../../../Theme";

type ProvinceAutoSelectProps = {
  provinces: string[];
  value: string | null;
  isRequired?: boolean;
  placeholder: string;
  hasError?: boolean;
  helperText?: any;
  label: string;
  [key: string]: any;
};

const useStyles = makeStyles({
  noOptions: {
    color: "red",
    backgroundColor: "white",
  },
});

const ProvinceAutoSelect: React.FC<ProvinceAutoSelectProps> = (
  props: ProvinceAutoSelectProps
): ReactElement => {
  const styles = useStyles();
  return (
    <>
      <Typography
        sx={{
          mb: 1,
          visibility: `${props.label === "" ? "hidden" : "block"}`,
        }}
      >
        {props.label}
        <Typography sx={{ color: "error.main" }} component="span">
          {props.isRequired && " *"}
        </Typography>
      </Typography>
      <div>
        <Autocomplete
          id={`province-autocomplete-${props.value}`}
          options={props.provinces}
          value={props.value}
          onChange={(event, value) => {
            props.setFieldValue(props.name, value);
          }}
          autoComplete
          classes={{
            noOptions: styles.noOptions,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                padding: "2px 2px",
                color: theme.palette.secondary.main,
                animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
                "& .MuiInputBase-root": {
                  backgroundColor: props.hasError ? "#fff" : "ghostwhite",
                },
                "& .MuiFormHelperText-root": {
                  color: "error.main",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: `${
                      props.hasError ? "error.main" : "ghostwhite"
                    }`,
                  },
                },
                "& .css-6vcfhj-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "40px",
                  padding: "0px 14px",
                },
              }}
              placeholder={props.placeholder}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ background: "white" }}>
              {option}
            </li>
          )}
        />
      </div>
      <FormHelperText sx={{ color: "error.main" }}>
        {props.helperText}
      </FormHelperText>
    </>
  );
};

export default ProvinceAutoSelect;
