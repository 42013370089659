import { useState } from "react";
import { Box, Button, Icon, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box as CustomBox } from "../../Custom";
import { InfoCircleIcon } from "../../../assets/svg";
import { usePlaidLink } from "react-plaid-link";
import { setPlaidAccessToken } from "../../../Redux/features/Applicant";
import { useAppDispatch } from "../../../Redux/store";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { getReferalCode } from "../../../utils";
import DebitForm from "./Form";
import DebitDetails from "./DebitDetails";
import ApplicationSubmission from "../ApplicationSubmission";
import AlertDialogSlide from "./Modal";
import plaidLogo from "../../../assets/svg/plaid_logo.svg";

type DebitFormInformation = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};
export default function DebitCardInformation(props: DebitFormInformation) {
  const dispatch = useAppDispatch();
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [agree, setAgree] = useState(false);
  const [openUI, setOpen] = useState(false);
  const [tokenCheck, setTokenCheck] = useState(false);
  const referalCode = getReferalCode();

  // Plaid authentication hook
  const { open, ready } = usePlaidLink({
    token: sessionStorage?.getItem("LinkToken"),
    onSuccess: (public_token, metadata) => {
      setTokenCheck(true);
      dispatch(setPlaidAccessToken({ public_token: public_token }))
        .unwrap()
        .then((response) => {
          sessionStorage.setItem("AccessToken", response.access_token);
          setTokenCheck(false);
          sessionStorage.removeItem("Address");
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    onExit: () => {
      toast.error("Error Occured");
      sessionStorage.setItem("tabValue", "3");
      setTokenCheck(false);
    },
  });

  const handleDetailsDialog = () => {
    setDetailsDialog((prev) => !prev);
  };

  const handleSubmissionDialog = () => {
    setSubmitDialog((prev) => !prev);
  };

  return (
    <Box
      sx={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Set Up Automatic Payments
          </Typography>
          <Icon
            sx={{
              width: "22px",
              height: "22px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              alt="This is info logo"
              src={InfoCircleIcon}
              style={{ width: "100%", height: "100%" }}
            />
          </Icon>
        </Box>
        <br />
        <Typography
          sx={{ color: grey[700], fontSize: "14px", marginBottom: "30px" }}
        >
          Setting up pre-authorized debit (PAD) for automatic payments is easy -
          just provide your bank account details to authorize automatic
          withdrawals on the due date of your payments.
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              paddingY: "13px",
              background: "#000000",
              borderRadius: "25px",
              color: "#ffffff",
              maxWidth: "400px",
              width: "100%",
              marginBottom: "50px",
              fontWeight: "bold",
              "&:hover": {
                background: "#000000",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              },
              "&:disabled": {
                color: "#ffffff", // Set the text color to black when disabled
              },
            }}
            variant="text"
            onClick={() => {
              open();
            }}
            disabled={!ready}
          >
            Same Day Authentication with{" "}
            <img src={plaidLogo} alt="This is plaid logo" />
            {tokenCheck && (
              <Typography sx={{ ml: 1 }}>
                <CircularProgress size={20} sx={{ color: "#ffffff" }} />
              </Typography>
            )}
          </Button>
          {!referalCode && (
            <>
              <Typography
                sx={{
                  fontWeight: "semibold",
                  fontSize: "16px",
                  textAlign: "center",
                  maxWidth: "400px",
                  width: "100%",
                }}
              >
                or you can manually fill out your debit details for
                authentication in the form below.
              </Typography>
              <Typography sx={{ color: grey[700], fontSize: "14px" }}>
                Manual authentication will take 2-3 business days.
              </Typography>
            </>
          )}
        </Box>
        <br />
        <br />
        <Typography sx={{ fontWeight: "bold" }}>
          Pre-authorized debit details
        </Typography>
        <DebitForm
          detailsDialog={detailsDialog}
          handleDetailsDialog={handleDetailsDialog}
          onSubmit={handleSubmissionDialog}
          agree={agree}
          setOpen={setOpen}
          setAgree={setAgree}
        />
        {detailsDialog && (
          <DebitDetails open={detailsDialog} close={handleDetailsDialog} />
        )}
        {openUI && <AlertDialogSlide setAgree={setAgree} setOpen={setOpen} />}
        {submitDialog && (
          <ApplicationSubmission
            open={submitDialog}
            close={handleSubmissionDialog}
            setTabIndex={props.setTabIndex}
          />
        )}
      </CustomBox>
    </Box>
  );
}
