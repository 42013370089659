import { useMemo, ReactElement, useEffect, useState } from "react";

import {
  Box,
  Grid,
  Divider,
  Typography,
  Icon,
  CircularProgress,
} from "@mui/material";

import { useFormik } from "formik";
import { grey } from "@mui/material/colors";
import { CreditCardFormSchema } from "../../../../schemas";
import {
  Select,
  Checkbox,
  StyledButton,
  MaskedInput,
  InputField,
} from "../../../Custom";
import { MasterCardLogo, VisaLogo } from "../../../../assets/png";
import { CreditLogo } from "../../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../../Redux/store";
import { toast } from "react-toastify";
import { listCountryNames, provinceSlugToProvince, provinceSlugs } from "../../../../utils";
import { createTheme } from "@mui/material/styles";
import {
  ICreditCard,
  addCreditCard,
  deleteCreditCard,
  editCreditCard,
} from "../../../../Redux/features/bnpl";
import { cardEditDetailsProps } from ".";
import ProvinceAutoSelect from "../../../Custom/ProvinceAutoSelect";
import useProvinces from "../../../../hooks/useProvinces";
import useCountries from "../../../../hooks/useCountries";
import { getPlaidLinkToken } from "../../../../Redux/features/Applicant";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// Defining Form Value Interface
interface MyFormValues {
  cardholderName: string;
  cardNumber: string;
  expDateMonth: string;
  expDateYear: string;
  cvv: string;
  address1: string;
  address2: string;
  postalCode: string;
  country: string;
  city: string;
  province: string;
  confirmBox: boolean;
}

// Defining Props
type CreditCardInformationFormProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
  handleClose: () => void;
  cardDetails?: ICreditCard;
  setCardEditDetails?: (cardEdit: cardEditDetailsProps) => void;
};

export default function CreditCardInformationForm(
  props: CreditCardInformationFormProps
): ReactElement {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.bnpl.loading);
  const [addressCheck, setAddressCheck] = useState(false);
  const [linkToken, setLinkToken] = useState("");
  const [tokenCheck] = useState(false);

  const address = useMemo(() => {
    const storedAddress = sessionStorage.getItem("Address");
    if (storedAddress) return JSON.parse(storedAddress);
    else
      return {
        line1: "",
        line2: "",
        country: "",
        city: "",
        state: "",
        zipcode: "",
        first_name: "",
        last_name: "",
      };
  }, []);

  //To Lift scrren to Top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Defining Initial Form values
  const initialValues: MyFormValues = useMemo(
    () => ({
      cardholderName: props.cardDetails?.cardholder || "",
      cardNumber: "",
      expDateMonth: props.cardDetails?.expiry_month || "",
      expDateYear: props.cardDetails?.expiry_year || "",
      cvv: "",
      addressBox: false,
      address1: props.cardDetails?.address?.line1 || "",
      address2: props.cardDetails?.address?.line2 || "",
      country: props.cardDetails?.address?.country || "Canada",
      city: props.cardDetails?.address?.city || "",
      province: props?.cardDetails?.address?.state? provinceSlugToProvince[props.cardDetails.address.state] : "",
      postalCode: props.cardDetails?.address?.zipcode || "",
      confirmBox: false,
    }),
    [
      props.cardDetails?.cardholder,
      props.cardDetails?.expiry_month,
      props.cardDetails?.expiry_year,
      props.cardDetails?.address?.line1,
      props.cardDetails?.address?.line2,
      props.cardDetails?.address?.zipcode,
      props.cardDetails?.address?.country,
      props.cardDetails?.address?.city,
      props.cardDetails?.address?.state,
    ]
  );

  const isCardEditing = useMemo(() => {
    return props.cardDetails?.cardholder && props?.cardDetails?.number;
  }, [props.cardDetails?.cardholder, props?.cardDetails?.number]);

  // Initializing Formik hook
  const formik = useFormik({
    initialValues,
    validationSchema: CreditCardFormSchema,

    // need to uncomment this when functionality is ready
    onSubmit: (values, action) => {
      const payload: any = {
        loan_application_id: sessionStorage.getItem("loanApplicationId"),
        number: values.cardNumber,
        cvv: values.cvv,
        expiry_month: values.expDateMonth,
        expiry_year: values.expDateYear,
        cardholder: values.cardholderName,
        address_line1: values.address1,
        address_line2: values.address2,
        address_city: values.city,
        address_country: listCountryNames[values.country],
        address_zipcode: values.postalCode,
        address_state: provinceSlugs[values.province],
      };
      if (
        props.cardDetails?.cardholder &&
        props.cardDetails?.number &&
        props.setCardEditDetails
      ) {
        // edit  a  card
        const brand = payload.number[0] == "5" ? "mastercard": "visa";
        dispatch(editCreditCard({ id: props.cardDetails?.id, ...payload, brand: brand }))
          .unwrap()
          .then(() => {
            sessionStorage.setItem("LinkToken", linkToken);
            props.setCardEditDetails &&
              props.setCardEditDetails({
                cardHolderName: "",
                number: "",
                isEditing: false,
              });
            props.handleClose();
          })
          .catch((err) => toast.error(err));
      } else {
        // add a card
        dispatch(addCreditCard(payload))
          .unwrap()
          .then(() => {
            sessionStorage.setItem("LinkToken", linkToken);
            props.handleClose();
          })
          .catch((err: any) => {
            toast.error(err);
          });
      }
    },
  });

  useEffect(() => {
    if (addressCheck) {
      formik.setValues({
        cardholderName: values.cardholderName,
        cardNumber: values.cardNumber,
        expDateMonth: values.expDateMonth,
        expDateYear: values.expDateYear,
        cvv: values.cvv,
        address1: address.line1,
        address2: address.line2,
        country: address.country,
        city: address.city,
        province: address.state,
        postalCode: address.zipcode,
        confirmBox: values.confirmBox,
      });
    } else {
      formik.setValues({
        cardholderName: values.cardholderName,
        cardNumber: values.cardNumber,
        expDateMonth: values.expDateMonth,
        expDateYear: values.expDateYear,
        cvv: values.cvv,
        address1: props.cardDetails?.address?.line1 || "",
        address2: props.cardDetails?.address?.line2 || "",
        country: "Canada",
        city: props.cardDetails?.address?.city || "",
        province: props?.cardDetails?.address?.state? provinceSlugToProvince[props.cardDetails.address.state] : "",
        postalCode: props.cardDetails?.address?.zipcode || "",
        confirmBox: values.confirmBox,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressCheck]);

  useEffect(() => {
    dispatch(getPlaidLinkToken([]))
      .unwrap()
      .then((response) => {
        setLinkToken(response?.link_token);
      })
      .catch((err) => toast.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, errors, touched, handleChange, handleSubmit } = formik;

  const memoizedProvinces = useProvinces(values.country);
  const memoizedCountries = useCountries();

  // credit card delete handler

  // Need to uncomment this when functionality is ready
  const handleCardDelete = () => {
    // if (!values.cardNumber) return;
    dispatch(deleteCreditCard(props.cardDetails?.id as number))
      .then(() => {
        props.handleClose();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <form style={{ margin: "1rem 0" }} onSubmit={handleSubmit}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Add a Credit Card
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Cardholder Name"
            placeholder="Jack Smith"
            name="cardholderName"
            value={values.cardholderName}
            onChange={handleChange}
            helperText={
              errors?.cardholderName && touched?.cardholderName
                ? errors?.cardholderName
                : ""
            }
            hasError={!!(errors?.cardholderName && touched?.cardholderName)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Card Number"
            placeholder="4000 1234 5678 9101"
            name="cardNumber"
            value={values.cardNumber}
            onChange={handleChange}
            helperText={
              errors?.cardNumber && touched?.cardNumber
                ? errors?.cardNumber
                : ""
            }
            hasError={!!(errors?.cardNumber && touched?.cardNumber)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={5} sm={5}>
                  <MaskedInput
                    label="Exp. Date"
                    placeholder="MM"
                    name="expDateMonth"
                    value={values.expDateMonth}
                    onChange={handleChange}
                    helperText={
                      errors?.expDateMonth && touched?.expDateMonth
                        ? errors?.expDateMonth
                        : ""
                    }
                    hasError={!!(errors?.expDateMonth && touched?.expDateMonth)}
                    isRequired
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      color: "secondary.main",
                      paddingTop: 4,
                    }}
                  >
                    /
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <MaskedInput
                    label=""
                    placeholder="YY"
                    name="expDateYear"
                    value={values.expDateYear}
                    onChange={handleChange}
                    helperText={
                      errors?.expDateYear && touched?.expDateYear
                        ? errors?.expDateYear
                        : ""
                    }
                    hasError={!!(errors?.expDateYear && touched?.expDateYear)}
                    isRequired
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MaskedInput
                maskChar={null}
                maskPlaceholder={null}
                label="CVV"
                placeholder="CVV"
                name="cvv"
                value={values.cvv}
                onChange={handleChange}
                helperText={errors?.cvv && touched?.cvv ? errors?.cvv : ""}
                hasError={!!(errors?.cvv && touched?.cvv)}
                type="password"
                isRequired
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100px" }}>
              <img
                src={MasterCardLogo}
                alt="MasterCard"
                width="100%"
                style={{
                  minHeight: "64px",
                }}
              />
            </Box>
            <Box sx={{ width: "100px" }}>
              <img src={VisaLogo} alt="Visa Card" width="100%" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Now displaying billing address form */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Billing Address
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Checkbox
          name="addressBox"
          value={addressCheck}
          onChange={() => {
            setAddressCheck(!addressCheck);
          }}
        />
        <Typography sx={{ color: grey[700], ml: 1 }}>
          Same As Home Address
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Address 1"
            placeholder="1234 51 Canada crescent"
            name="address1"
            value={values.address1}
            onChange={handleChange}
            helperText={
              errors?.address1 && touched?.address1 ? errors?.address1 : ""
            }
            hasError={!!(errors?.address1 && touched?.address1)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Address 2"
            placeholder="Apt/Unit/Suite"
            name="address2"
            value={values.address2}
            onChange={handleChange}
            helperText={
              errors?.address2 && touched?.address2 ? errors?.address2 : ""
            }
            hasError={!!(errors?.address2 && touched?.address2)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="City"
            placeholder="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            isRequired
            helperText={errors?.city && touched?.city ? errors?.city : ""}
            hasError={!!(errors?.city && touched?.city)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProvinceAutoSelect
            name="province"
            label="Province"
            placeholder="Choose Province"
            value={values.province}
            onChange={handleChange}
            hasError={!!(errors?.province && touched?.province)}
            provinces={memoizedProvinces()}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            helperText={
              errors?.province && touched?.province ? errors?.province : ""
            }
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Postal Code"
            placeholder="Postal Code"
            name="postalCode"
            value={values.postalCode.toUpperCase()}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.postalCode && touched?.postalCode
                ? errors?.postalCode
                : ""
            }
            hasError={!!(errors?.postalCode && touched?.postalCode)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label="Country"
            placeholder="Choose Country"
            name="country"
            options={memoizedCountries}
            value={values.country}
            onChange={handleChange}
            hasError={!!(errors?.country && touched?.country)}
            helperText={
              errors?.country && touched?.country ? errors?.country : ""
            }
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            isRequired
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 4 }}>
        <Typography sx={{ fontWeight: "bold", letterSpacing: 0.5 }}>
          In order to proceed, you must be the primary cardholder of a valid
          Canadian Visa or Mastercard.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Checkbox
            name="confirmBox"
            value={values.confirmBox}
            onChange={handleChange}
            hasError={!!(errors?.confirmBox && touched?.confirmBox)}
          />
          <Typography sx={{ color: grey[700], ml: 1 }}>Confirm</Typography>
        </Box>
      </Grid>
      <Divider sx={{ my: 4 }} />
      {isCardEditing ? (
        <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
          {/* Need to uncomment this when functionality is ready */}
          <StyledButton
            variant="contained"
            type="submit"
            bgColor="#fdcdd1"
            hoverColor="#F93A5B"
            textColor="#F93A5B"
            textHoverColor="#fff"
            onClick={handleCardDelete}
          >
            <Typography>Delete</Typography>
          </StyledButton>

          <StyledButton
            variant="contained"
            type="submit"
            disabled={loading || tokenCheck || !props.cardDetails}
          >
            <Typography>Continue</Typography>
            {(loading || tokenCheck) && (
              <Typography sx={{ ml: 2 }}>
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              </Typography>
            )}
          </StyledButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Secured By</Typography>
            <Icon
              sx={{
                width: "100px",
                height: "80px",
                display: "flex",
                alignItems: "center",
                ml: 2,
              }}
            >
              <img
                src={CreditLogo}
                alt="Logo"
                style={{ width: "100%", height: "100%" }}
              />
            </Icon>
          </Box>
          <StyledButton
            variant="contained"
            type="submit"
            disabled={loading || tokenCheck}
          >
            <Typography>Continue</Typography>
            {(loading || tokenCheck) && (
              <Typography sx={{ ml: 2 }}>
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              </Typography>
            )}
          </StyledButton>
        </Box>
      )}
    </form>
  );
}
