import React, { FC, ReactElement } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// Defining Prop types for our CustomBox
type BoxProps = {
  sm?: Object;
  md?: Object;
  lg?: Object;
  children: React.ReactNode;
  [key: string]: any;
};

// Defining our Custom box
const CustomBoxWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "50%",
  },
}));

const CustomBox: FC<BoxProps> = (props: BoxProps): ReactElement => (
  <CustomBoxWrapper>{props.children}</CustomBoxWrapper>
);
export default CustomBox;
