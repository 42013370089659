import { FC, ReactElement, useState } from "react";
import { Box, Typography, SliderProps, TextField, InputAdornment } from "@mui/material";
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // Other theme configurations
});

// Define SliderProps type
type CustomSliderProps = SliderProps & {
  value: number;
  onChange: (event: any, value: number) => void;
  setSliderValue: (value: any) => void;
};

const Index: FC<CustomSliderProps> = (
  props: CustomSliderProps
): ReactElement => {
  const { value, onChange, setSliderValue } = props;
  const [color, setColor] = useState("#616161");

  return (
    <Box
      sx={{
        width: "30%",
        [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
          width: "65%",
        },
      }}
    >
      {/* Slider for choosing loan amount */}

      {/* <Slider
        defaultValue={0}
        min={100}
        max={25000}
        value={value}
        step={25}
        onChange={onChange}
        aria-label="Default"
        valueLabelDisplay="auto"
        sx={{

          "& .MuiSlider-rail" : {
            backgroundColor: '#F5F8FF',
            border: "2px solid #F5F8FF",
          }
        }}
      /> */}

      <TextField
        sx={{
          "& .MuiInputBase-root": {
            width: "302px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px 10px",
            width: "100%",
            fontSize: "large"
          },
        }}
        InputProps={{
          notched: false,
          startAdornment: (<InputAdornment position="start">$</InputAdornment>),
          inputProps: {
            max: 25000,
            min: 100,
          },
        }}
        InputLabelProps={{ shrink: false }}
        type="number"
        label=""
        value={value}
        onChange={(e: any) => {
          let value = Number.parseInt(e.target.value, 10);
          if (isNaN(value)) {
            // Handle non-numeric input
            value = 0;
          }
          if (e.target.value.match(/^0+/) != null && e.target.value !== "") {
            // Remove leading zeros and parse as an integer
            e.target.value = parseInt(e.target.value, 10);
          }
          if (value > 25000) {
            value = 25000;
          }
          // Update the color state based on the new value
          if (value < 100) {
            setColor("#FF0000");
          } else {
            setColor("#616161");
          }
          setSliderValue(value);
        }}
      />
      <Typography
        className="apr_value"
        style={{ color }}
        variant="body2"
        sx={{ color: grey[700], marginTop: "10px" }}
      >
        Min. $100.00 and Max. $25,000.00
      </Typography>
    </Box>
  );
};

export default Index;
