import { Typography } from "@mui/material";
const Section5 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 5: Privacy and Communication
      </Typography>
      <Typography sx={{ mb: 2 }}>
        It is the responsibility of the Borrower to update the contact
        information on file as soon as possible. Failure to notify may result in
        miscommunication. ONTAB cannot be held responsible if you, the Borrower,
        fail to provide the most current contact information.
      </Typography>
    </div>
  );
};

export default Section5;
