import React from "react";
import { usePlaidLink } from "react-plaid-link";
import { setPlaidAccessToken } from "../../../Redux/features/Applicant";
import { useAppDispatch } from "../../../Redux/store";
import plaidLogo from "../../../assets/svg/plaid_logo.svg";
import { toast } from "react-toastify";
import { Button, Typography, CircularProgress } from "@mui/material";

type PlaidButtonProps = {
  setIsPlaidAuth: React.Dispatch<React.SetStateAction<boolean>>;
};

const PlaidButton = (props: PlaidButtonProps) => {
  const [tokenCheck, setTokenCheck] = React.useState(false);
  const dispatch = useAppDispatch();

  const { open, ready } = usePlaidLink({
    token: sessionStorage?.getItem("LinkToken"),
    onSuccess: (public_token, metadata) => {
      setTokenCheck(true);
      dispatch(setPlaidAccessToken({ public_token: public_token }))
        .unwrap()
        .then((response) => {
          sessionStorage.setItem("AccessToken", response.access_token);
          props.setIsPlaidAuth(true);
          setTokenCheck(false);
          sessionStorage.removeItem("Address");
        })
        .catch((err) => {
          toast.error(err);
        });
    },
    onExit: () => {
      toast.error("Please connect your bank account to continue.");
      sessionStorage.setItem("tabValue", "3");
      setTokenCheck(false);
    },
  });

  return (
    <Button
      sx={{
        paddingY: "13px",
        background: "#000000",
        borderRadius: "25px",
        color: "#ffffff",
        maxWidth: "400px",
        width: "100%",
        margin: "1rem 0",
        fontWeight: "bold",
        "&:hover": {
          background: "#000000",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        },
        "&:disabled": {
          color: "#ffffff", // Set the text color to black when disabled
        },
      }}
      variant="text"
      onClick={() => {
        open();
      }}
      disabled={!ready}
    >
      Same Day Authentication with <img src={plaidLogo} alt="plaid" />
      {tokenCheck && (
        <Typography sx={{ ml: 1 }}>
          <CircularProgress size={20} sx={{ color: "#ffffff" }} />
        </Typography>
      )}
    </Button>
  );
};

export default PlaidButton;
