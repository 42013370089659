import { Box, Typography } from "@mui/material";

type RSDetailsProps = {
  title: string;
  description: string;
};

// Review And Submit Details Helper Component
const RSDetails = (props: RSDetailsProps) => {
  const fontStyling = {
    fontSize: "14px",
    color: "#373D47",
  };

  return (
    <Box
      sx={{
        borderBottom: "2px solid ghostwhite",
        padding: "1rem 1rem 0.2rem 1rem",
      }}
    >
      <Box sx={{ width: "80%" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box sx={{minWidth: "50%"}}>
          <Typography
            sx={{ ...fontStyling, lineHeight: "20px", fontWeight: 400 }}
          >
            {props.title}
          </Typography>
          </Box>
          <Box sx={{minWidth: '50%',  display: "flex", alignItems: "center", justifyContent: "start"}}>
          <Typography
            sx={{
              ...fontStyling,
              lineHeight: "20px",
              fontWeight: 600,
            }}
          >
            {props.description}
          </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RSDetails;
