import { FC, ReactElement } from "react";
import {
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  FormControl,
} from "@mui/material";
import theme from "../../../Theme";
import { ErrorAnimation } from "../../../animation";
import { getReferalCode } from "../../../utils";

// Defining Select Props
type SelectProps = {
  label: string;
  placeholder: string;
  name: string;
  options: Array<string>;
  value: string;
  hasError?: boolean;
  isRequired?: boolean;
  helperText?: any;
  isDisabled?: boolean;
  [key: string]: any;
};

const CustomSelect: FC<SelectProps> = (props: SelectProps): ReactElement => {
  const referalCode = getReferalCode();
  return (
    <>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        {props.label}
        <Typography sx={{ color: "error.main" }} component="span">
          {props.isRequired && " *"}
        </Typography>
      </Typography>
      <FormControl fullWidth>
        <Select
          size="small"
          name={props.name}
          value={props.value}
          onChange={props?.onChange}
          sx={{
            backgroundColor: props.hasError ? "#fff" : "ghostwhite",
            padding: "2px 2px",
            animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: `${props.hasError ? "error.main" : "ghostwhite"}`,
            },
          }}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.main,
              },
            },
          }}
          renderValue={(selected: string) => {
            if (selected.length === 0) {
              return <Typography>{props.placeholder}</Typography>;
            }
            return selected;
          }}
          disabled={
            props.name === "country" ||
            referalCode ||
            (sessionStorage?.getItem("AccessToken") &&
              props.name === "accountType")
              ? true
              : false
          }
        >
          <MenuItem disabled value="">
            {props.placeholder}
          </MenuItem>
          {props.options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: "error.main" }}>
          {props.helperText}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;
