import { useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { getReferalCode } from "../../../utils";
import { Typography } from "@mui/material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props: any) {
  const referralCode = getReferalCode();
  const [open] = useState(true);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .css-1bnrpvp-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "white",
            width: "500px",
            height: "300px",
          },
          "& .css-6owf5a": {
            backgroundColor: "white",
            width: "400px",
            height: "400px",
          },
        }}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", color: "lightgray" }}
            />
          </div>
        </DialogTitle>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700px",
            }}
          >
            DISCLAIMER
          </div>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {referralCode ? (
              <Typography>
                At ONTAB, we will safeguard and protect your personal data. By
                submitting your application, you agree to check your submitted
                information with our financial regulators and partners. For more
                information, please visit ontab.com/privacy.
              </Typography>
            ) : (
              <Typography>
                ONTAB uses 3rd party service providers to verify your identity
                to meet our regulatory obligations. By submitting your
                application, you agree to sharing your information with 3rd
                parties. For more information, please visit ontab.com/privacy.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              color: "#fff",
              px: 4,
              textTransform: "capitalize",
              backgroundColor: "#1b55f5",
              "&:disabled": {
                backgroundColor: "#3075FF",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#1b55f5",
              },
            }}
            onClick={() => {
              props.setAgree(true);
              props.setOpen(false);
            }}
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
