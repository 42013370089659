import { Typography } from "@mui/material";
const PadRequest = () => {
  return (
    <div className="section">
      <Typography className="agreement-list-heading">
        PAD request options:
      </Typography>
      <Typography className="pad-request-list" sx={{ my: 2 }}>
        <ol>
          <li>New PAD Setup</li>
          <li>
            Change Existing PAD Account
            <ol className="list-style-alpha">
              <li>
                PADs cannot be changed for a payment due within 5 business days.
              </li>
            </ol>
          </li>
          <li>
            Cancel Existing PAD Account
            <ol className="list-style-alpha">
              <li>
                To cancel an existing PAD, please provide alternative payment
                details or pay your remaining balance in full (See Terms and
                Conditions).
              </li>
            </ol>
          </li>
        </ol>
      </Typography>
      <Typography sx={{ mb: 2 }}>
        IMPORTANT: For joint accounts, all signatures are required.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        By signing below, you authorize ONTAB to withdraw the above mentioned
        Loan Payments Amount from your Canadian Bank Account (the "PAD''
        Account). You acknowledge that you have read and agree to the Terms and
        Conditions listed both above and below.
      </Typography>
    </div>
  );
};

export default PadRequest;
