import React, { FC, ReactElement } from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

// Defining Props for StyledContainer
type ContainerProps = {
  children: React.ReactNode;
  [key: string]: any;
};

// Defining Our Styled Container
const StyledContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
const index: FC<ContainerProps> = (props: ContainerProps): ReactElement => (
  <StyledContainer>{props.children}</StyledContainer>
);

export default index;
