import { useMemo } from "react";
import { Modal, Box } from "@mui/material";
import { useAppSelector } from "../../../../Redux/store";
import { ICreditCard } from "../../../../Redux/features/bnpl";
import { CircularButton } from "../../../Custom";
import { cardEditDetailsProps } from ".";
import CreditCardForm from "./CreditCardEntryForm";
import theme from "../../../../Theme";

type AddACardProps = {
  addACardModal: boolean;
  handleClose: () => void;
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
  cardEditDetails?: cardEditDetailsProps;
  setCardEditDetails?: (cardEdit: cardEditDetailsProps) => void;
};

const AddACardModal = (props: AddACardProps) => {
  const creditCards = useAppSelector((state) => state.bnpl.creditCards);

  const cardDetails = useMemo(() => {
    return props.cardEditDetails?.isEditing
      ? creditCards.filter(
          (c: ICreditCard) =>
            c.number === props.cardEditDetails?.number &&
            c.cardholder === props.cardEditDetails?.cardHolderName
        )?.[0]
      : "";

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cardEditDetails]);

  return (
    <Modal
      open={props.addACardModal}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        width: "60%",
        left: "25%; !important",
        padding: '2rem 0',
        [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
          width: "100%",
          left: "0 !important",
          padding: "1rem",
        },
        overflowY: "scroll",
         "& > .MuiBackdrop-root" : {
            backgroundColor: "#F0F5FF",
         }   
      }}
    >
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          padding: '0 4rem',
          backgroundColor: "white",
          boxShadow: "0px 0px 2px 0px #F0F5FF !important",
          border: "1px solid #F0F5FF",
          borderRadius: "10px",
          width: "80%",
          [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
            padding: '0 1rem',
          },
          position: 'relative'
        }}
      >
       
          <Box
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              width: "fit-content",
            }}
          >
            <CircularButton
              onClick={() => {
                props.handleClose();
                if (props.setCardEditDetails)
                  props.setCardEditDetails({
                    cardHolderName: "",
                    number: "",
                    isEditing: false,
                  });
              }}
            >
              x
            </CircularButton>
          </Box>
          <CreditCardForm
            setTabIndex={props.setTabIndex}
            handleClose={props.handleClose}
            cardDetails={cardDetails}
            setCardEditDetails={props.setCardEditDetails}
          />
        
      </Box>
    </Modal>
  );
};

export default AddACardModal;
