import { ReactElement, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getReferalCode } from "../../../utils";
import ApplicationSubmission from "../ApplicationSubmission";
import CreditCardInformationForm from "./Form";
import CustomBox from "../../Custom/Box";

type CreditCardInformationProps = {
  provider: string | "OnTab";
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

export default function CreditCardInformation(
  props: CreditCardInformationProps
): ReactElement {
  const [submitDialog, setSubmitDialog] = useState(false);

  // submissionDialog Handler
  const handleSubmissionDialog = () => {
    setSubmitDialog((prev) => !prev);
  };

  const referalCode = getReferalCode();

  return (
    <Box
      sx={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {submitDialog ? (
        <ApplicationSubmission
          open={submitDialog}
          close={handleSubmissionDialog}
          setTabIndex={props.setTabIndex}
        />
      ) : (
        <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Credit Card Information
          </Typography>
          <Typography
            sx={{
              color: "secondary.main",
              fontSize: "15px",
              my: 2,
              letterSpacing: 0.5,
            }}
          >
            {referalCode
              ? `Use a credit card with a limit equal to or higher than the requested amount. We'll authorize your card, adjusting the authorized amount as you make payments:
`
              : `Your Visa credit card information is required to transfer the funds securely.
            Once the funds are applied, we will continually authorize your credit card for the outstanding loan balance.`}
          </Typography>
          <CreditCardInformationForm
            setTabIndex={props.setTabIndex}
            setSubmitDialog={setSubmitDialog}
          />
        </CustomBox>
      )}
    </Box>
  );
}
