import { Box, Typography } from "@mui/material";
import { ICreditCard } from "../../../../Redux/features/bnpl";
import { getCardLogo } from "../../../../utils";
import { cardEditDetailsProps } from ".";
import { CircularButton } from "../../../Custom";
import InputField from "../../../Custom/InputField";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
type CreditCardProps = {
  handleSplitChange: () => void;
  card: ICreditCard;
  toggleModal: any;
  setCardEditDetails: (cardEdit: cardEditDetailsProps) => void;
  preData: boolean;
};
const CreditCard = (props: CreditCardProps) => {
  const logo = getCardLogo(props.card.brand);
  const height = props.card.brand == "visa" ? 50 : 32;
  const [, setSplitAmounts] = useState({});
  /* Need to uncomment when functionality is ready */
  const handleEdit = () => {
    props.setCardEditDetails({
      number: props.card.number,
      cardHolderName: props.card.cardholder,
      isEditing: true,
    });
    props.toggleModal();
  };
  const handleSplit = (e: any) => {
    e.stopPropagation();
    setSplitAmounts((prevSplitAmounts) => {
      const updatedSplitAmounts = {
        splitAmount: parseFloat(e.target.value ? e.target.value : "0"),
      };
      localStorage.setItem(
        `card_${props.card.id}`,
        updatedSplitAmounts ? JSON.stringify(updatedSplitAmounts) : "0"
      );
      props.handleSplitChange();
      return updatedSplitAmounts;
    });
    if (props.preData == false) {
      localStorage.setItem("amount", e.target.value);
    }
    if (e.target.value.trim() == "") {
      localStorage.setItem("errors", "true");
    } else {
      localStorage.setItem("errors", "false");
    }
  };
  useEffect(() => {
    localStorage.setItem("errors", "true");
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ border: "1px solid ghostwhite", padding: "1rem" }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <img src={logo} alt="Visa" width={50} height={height} />
        <Typography sx={{ mx: 2 }}>
          {props.card.cardholder}
          <Typography>
            {`**** **** **** ${props.card.number?.substring(
              props.card.number.length - 4,
              props.card.number.length
            )}`}
          </Typography>
        </Typography>
        {/* Need to uncomment when functionality is ready */}
        <CircularButton onClick={handleEdit}>
          <EditIcon />
        </CircularButton>
      </Box>
      {/* Need to uncomment when functionality is ready */}
      <Box display="flex" justifyContent="end">
        <Box display="flex" alignItems="center" sx={{ mt: 2, width: "80%" }}>
          <InputField
            label="Amount"
            placeholder="$100.00"
            name="creditCardAmount"
            onChange={(e: any) => handleSplit(e)}
            value={
              props?.preData === false
                ? localStorage.getItem("amount")
                : localStorage
                    .getItem(`card_${props?.card?.id}`)
                    ?.split(":")[1]
                    ?.match(/\d+/g)
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CreditCard;