import { Typography } from "@mui/material";
const Section6 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 6. Electronic mail consent
      </Typography>
      <Typography sx={{ mb: 2 }}>
        ONTAB Inc. makes data security and protecting your personal information
        a priority. We adhere to all applicable Privacy legislation including
        the Personal Information Protection and Electronic Documents Act
        (PIPEDA) and Canadian Anti-Spam Legislation (CASL). For any questions or
        concerns regarding privacy, please contact the Privacy Officer at
        <a href="privacy@ontab.com">privacy@ontab.com</a>.
      </Typography>
    </div>
  );
};

export default Section6;
