export function calculatePerMonthInstallment(
  rate: any,
  nper: any,
  pv: any,
  fv = 0,
  type = 0
) {
  if (rate === 0) {
    return (-(pv + fv) / nper);
  }
  var pmt =
    (rate * (pv * Math.pow(1 + rate, nper) + fv)) /
    ((1 + rate * type) * (Math.pow(1 + rate, nper) - 1));
  const amount = pmt;
  return amount;
}

export function calculateTotalAmount(
  rate: any,
  nper: any,
  pv: any,
  fv = 0,
  type = 0
) {
  if (rate === 0) {
    return -(pv + fv) / nper;
  }
  var pmt =
    (rate * (pv * Math.pow(1 + rate, nper) + fv)) /
    ((1 + rate * type) * (Math.pow(1 + rate, nper) - 1));
  return (pmt * nper);
}

export const calculateDiscountedPerMonthInstallment = (
  amount: number,
  months: number
) => {
  return (amount / months);
};
