import { FC, ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Box, Checkbox } from "@mui/material";
import { ErrorAnimation } from "../../../animation";

// Defining Checkbox props
type CheckboxProps = {
  name: string;
  value: boolean;
  helperText?: string;
  hasError?: boolean;
  [key: string]: any;
};

// Defining our styled Checkbox
const CustomCheckBox = styled(Checkbox)(() => ({
  backgroundColor: "#F5F8FF",
  borderRadius: 3,
  width: 16,
  height: 16,
  border: "1px solid grey",
  color: "#F5F8FF",
  "& .MuiCheckbox-root": {
    padding: 0,
  },
}));

const index: FC<CheckboxProps> = (props: CheckboxProps): ReactElement => (
  <Box>
    <CustomCheckBox
      value={props.value}
      name={props.name}
      onChange={props.onChange}
      sx={{
        animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
        "& .MuiSvgIcon-root": {
          color: `${props.hasError ? "error.main" : ""}`,
        },
        "&.Mui-checked": {
          color: `${props.hasError ? "error.main" : ""}`,
        },
        "&.Mui-focusVisible .MuiOutlinedInput-notchedOutline": {
          borderColor: `${props.hasError ? "error.main" : ""}`,
        },
      }}
      disabled={props.name === "jointAccount" ? true : false}
    />
  </Box>
);
export default index;
