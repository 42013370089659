import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    customText?: {
      dark: string;
      medium: string;
      light: string;
    };
    primaryButton?: {
      main: string;
    };
    primaryActive?: {
      main: string;
    };
  }
}

// Define the theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#3075FF",
    },
    info: {
      main: "#FFFF00",
    },
    error: {
      main: "#F93A5B",
    },
    success: {
      main: "#00C993",
    },
    secondary: {
      main: "#6c757d",
      light: "#f6f9ff",
    },
    background: {
      default: "#F0F5FF",
      paper: "rgba(246, 249, 255, 0.5)",
    },
    customText: {
      dark: "#050C19",
      medium: "#373D47",
      light: "#F0F5FF",
    },
    primaryButton: {
      main: "#3075FF",
    },
    primaryActive: {
      main: "#1b55f5",
    },
  },
  typography: {
    fontFamily: "Manrope, sans-serif",
    h1: {
      fontSize: "4rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },
});

export default theme;
