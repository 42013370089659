import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { Header, Main, Footer } from "./Components";
import { Routes, Route } from "react-router-dom";
import theme from "./Theme";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const tokenString = JSON.parse(sessionStorage.getItem("token") || "0");
  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <Header tokenString={tokenString} />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/apply" element={<Main />} />
          </Routes>
          <Footer />
        </>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
