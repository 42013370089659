import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Toolbar, Icon } from "@mui/material";
import { CreditLogo } from "../../assets/svg";

export default function Header({ tokenString }: any) {
  return (
    <Box>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          sx={{
            ml: "4rem",
            mr: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Icon
            sx={{
              width: "140px",
              height: "80px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={CreditLogo}
              alt="Logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Icon>
          {/* {tokenString === 0 ?
            <Button
              variant="text"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                borderRadius: '25px',
                p: '10px 24px',
                textTransform: 'capitalize',
              }}
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
            :
            <Button
              variant="text"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                borderRadius: '25px',
                p: '10px 24px',
                textTransform: 'capitalize',
              }}
              onClick={() => { sessionStorage.clear(); window.location.reload(); }}
            >
              Logout
            </Button>
          } */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
