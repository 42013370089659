const apiUrl = process.env.REACT_APP_API_URL;
export const HEADER_TABS = [
  "Loan Information",
  "Applicant Information",
  "Credit Card Verification",
  "Pre-Authorized Debit Form",
];
export const BNPL_HEADER_TABS = [
  "Loan Information",
  "Applicant Information",
  "Credit Card Authorization",
  "Payment Method",
  "Review and Submit",
];

export const FOOTER_TABS_BNPL = [
  { name: "Account", href: `${apiUrl}/login` },
  { name: "Privacy Policy", href: `https://ontab.com/privacy/` },
  { name: "Terms and Conditions", href: `https://ontab.com/terms` },
  { name: "ONTAB Services", href: "https://ontab.services/" },
  { name: "Contact", href: `https://ontab.com/contact` },
];

export const FOOTER_TABS = [...FOOTER_TABS_BNPL, { name: "ONTAB Verify", href: "https://ontab.com/verify/" },]

export const BANK_NAMES = [
  "Toronto-Dominion Bank",
  "Royal Bank of Canada",
  "Bank of Nova Scotia",
  "Bank of Montreal",
  "CIBC",
  "Desjardins Group",
  "National Bank",
  "HSBC Bank",
];

export const ACCOUNT_TYPES = ["Checking", "Savings"];

export const VISA_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
export const MASTER_CARD_REGEX =  /^5[1-5][0-9]{14}$/;