import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Icon,
  Divider,
  Button,
} from "@mui/material";
import { StyledButton, Box as CustomBox } from "../../../Custom";
import { useAppDispatch, useAppSelector } from "../../../../Redux/store";
import { PlaidButton } from "../../../Custom";
import { CreditLogo } from "../../../../assets/svg";
import { getCardLogo } from "../../../../utils";
import { getBankDetails } from "../../../../Redux/features/Applicant";
import { toast } from "react-toastify";
import AddACardModal from "../CreditCardAuthorization/AddACardModal";
import theme from "../../../../Theme";
import "./index.css";

type PreAuthorizedDebitProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

const PreAuthorizedDebit = (props: PreAuthorizedDebitProps) => {
  const [isSelected, setIsSelected] = useState<number>(2);
  const [addCardModal, setAddCardModal] = useState(false);
  const creditCards = useAppSelector((state) => state.bnpl.creditCards);
  const [cardSelected, setCardSelected] = useState<string>(creditCards[0]?.id);
  const [isPlaidAuth, setIsPlaidAuth] = useState(false);
  const dispatch = useAppDispatch();

  const [bankDetails, setBankDetails] = useState({
    institution_number: "",
    transit_number: "",
    account_number: "",
    account_type: "",
  });

  function checkMainAccount(id: any) {

    if (id) {
      const selectedCard = creditCards.filter((card: any) => card.id == id);

      localStorage.setItem("mainAccountId", selectedCard[0]?.id);
    } else {
      localStorage.setItem("mainAccountId", creditCards[0]?.id);
    }
  }

  // Fetching BankDetails from Plaid
  useEffect(() => {
    if (isPlaidAuth || sessionStorage?.getItem("AccessToken")) {
      dispatch(getBankDetails([]))
        .unwrap()
        .then((response) => {
          setBankDetails(response.bank);
          setIsPlaidAuth(false);
        })
        .catch((err: any) => {
          toast.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaidAuth, isSelected]);

  // Store credit source as well
  useEffect(() => {
    sessionStorage.setItem("credit_source", "card");
    checkMainAccount(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form Handler
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      cardSelected ||
      (bankDetails?.account_number &&
        bankDetails.institution_number &&
        bankDetails.transit_number)
    ) {
      props.setTabIndex((prev) => prev + 1);
    } else toast.error("Please choose atleast one payment option");
  };

  const handlePaymentOption = (opt: number) => {
    // setCardSelected(0);
    setIsSelected(opt);
    if (opt === 1) {
      sessionStorage.setItem("credit_source", "bank");
    } else if (opt === 2) {
      sessionStorage.setItem("credit_source", "card");
    }
  };

  const paymentOptions = useMemo(() => {
    return [
      {
        id: 1,
        title: "Bank Account",
        description: "Automatic debit from your account",
      },
      {
        id: 2,
        title: "Credit Card",
        description: "Automatic charges to your credit card",
      },
    ];
  }, []);

  return (
    <>
      {addCardModal ? (
        <>
          <AddACardModal
            setTabIndex={props.setTabIndex}
            addACardModal={addCardModal}
            handleClose={() => setAddCardModal(false)}
          />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
              <Typography
                sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
              >
                Select a Payment Option
              </Typography>
              <Typography className="payment-opt-base">
                Select a payment method for your monthly installments.
              </Typography>
              {/* Display payment options */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  my: 4,
                  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {
                    flexDirection: "column",
                    width: "100%",
                  },
                }}
              >
                {paymentOptions.map((option) => (
                  <>
                    <Card
                      key={option.id}
                      onClick={() => handlePaymentOption(option.id)}
                      sx={{
                        width: "100%",
                        marginBottom: "1rem",
                        [`@media (min-width:${theme.breakpoints.values.sm}px)`]:
                          {
                            width: "100%",
                            margin: "0",
                          },

                        padding: 1,
                        backgroundColor:
                          isSelected === option.id ? "#FFF" : "#fbfbff",
                        border:
                          isSelected === option.id
                            ? "1px solid #007AFF"
                            : "none",
                        borderRadius: "8px",
                        boxShadow: "0",
                        textAlign: "center",
                      }}
                    >
                      <CardContent>
                        <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                          {option.title}
                        </Typography>
                        <Typography color="text.secondary">
                          {option.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                ))}
              </Box>

              {/* Display details for selected option */}
              {isSelected === 1 ? (
                <>
                  <Box>
                    <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                      <Typography sx={{ fontWeight: "bold", mr: 2 }}>
                        Set Up Automatic Payments
                      </Typography>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2ZM11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V16ZM13 8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8C11 8.6 11.4 9 12 9C12.6 9 13 8.6 13 8Z"
                          fill="#050C19"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </Box>
                    <Typography color="text.secondary" sx={{ mb: 2 }}>
                      Setting up pre-authorized debit (PAD) for automatic
                      payments is easy. ONTAB uses Plaid to connect to your bank
                      account. Please login with Plaid to select and authorize
                      automatic withdrawals for your payments.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ my: 2 }}
                    >
                      <PlaidButton setIsPlaidAuth={setIsPlaidAuth} />
                    </Box>
                    <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                      Pre-Authorized Debit Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            borderBottom: "2px solid ghostwhite",
                            padding: "0 2rem 0.5rem 2rem",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography color="secondary">
                              Bank Institute Number
                            </Typography>
                            <Box
                              sx={{
                                minWidth: "200px",
                                display: "flex",
                                justifyContent: "end",
                                [`@media (min-width:${theme.breakpoints.values.lg}px)`]:
                                  {
                                    justifyContent: "start",
                                    minWidth: "300px",
                                  },
                                alignItems: "center",
                              }}
                            >
                              <Typography color="secondary">
                                {bankDetails?.institution_number ||
                                  "----------"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            borderBottom: "2px solid ghostwhite",
                            padding: "0 2rem 0.5rem 2rem",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography color="secondary">
                              Bank Transit Number
                            </Typography>
                            <Box
                              sx={{
                                minWidth: "200px",
                                display: "flex",
                                justifyContent: "end",
                                [`@media (min-width:${theme.breakpoints.values.lg}px)`]:
                                  {
                                    justifyContent: "start",
                                    minWidth: "300px",
                                  },
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <Typography color="secondary">
                                {bankDetails?.transit_number || "----------"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            borderBottom: "2px solid ghostwhite",
                            padding: "0 2rem 0.5rem 2rem",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography color="secondary">
                              Account Number
                            </Typography>
                            <Box
                              sx={{
                                minWidth: "200px",
                                display: "flex",
                                justifyContent: "end",
                                [`@media (min-width:${theme.breakpoints.values.lg}px)`]:
                                  {
                                    justifyContent: "start",
                                    minWidth: "300px",
                                  },
                                alignItems: "center",
                              }}
                            >
                              <Typography color="secondary">
                                {bankDetails?.account_number || "----------"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            borderBottom: "2px solid ghostwhite",
                            padding: "0 2rem 0.5rem 2rem",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography color="secondary">
                              Account Type
                            </Typography>
                            <Box
                              sx={{
                                minWidth: "200px",
                                display: "flex",
                                justifyContent: "end",
                                [`@media (min-width:${theme.breakpoints.values.lg}px)`]:
                                  {
                                    justifyContent: "start",
                                    minWidth: "300px",
                                  },
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <Typography color="secondary">
                                {bankDetails?.account_type || "----------"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    {/* Needs to be uncomment when functionality has been developed */}
                    {/* <Typography sx={{ mb: 2 }}>Select a Credit Card</Typography>  */}
                    <Grid container spacing={2} sx={{ my: 4 }}>
                      {creditCards?.map((card: any, index: number) => (
                        <Grid item>
                          <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                              backgroundColor:
                                cardSelected === card?.id ? "#FFF" : "#fbfbff",
                              border:
                                cardSelected === card?.id
                                  ? "1px solid #007AFF"
                                  : "none",
                              padding: "1rem",
                            }}
                            onClick={() => {
                              checkMainAccount(card?.id);
                              setCardSelected(card?.id);
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <img
                                src={getCardLogo(card?.brand)}
                                alt="Visa"
                                width={50}
                              />
                              <Typography sx={{ mx: 2 }}>
                                {card.cardholder}
                                <Typography>
                                  {`**** **** **** ${card?.number?.substring(
                                    card?.number?.length - 4,
                                    card?.number?.length
                                  )}`}
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    {/* Needs to be uncommented when functionality has been provided */}
                    {/* {creditCards.length < 4 && (
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={() => setAddCardModal((prev) => !prev)}
                          sx={{
                            backgroundColor: "ghostwhite",
                            color: "#3075FF",
                            textTransform: "capitalize",
                            borderRadius: "20px",
                            width: "200px",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "ghostwhite",
                              color: "#3075FF",
                            },
                          }}
                          type="submit"
                        >
                          <Typography> Add A New Card</Typography>
                        </Button>
                      </Box>
                    )} */}
                  </Box>
                </>
              )}

              {/* Confirmation Section */}
              <Divider sx={{ my: 4 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography>Secured By</Typography>
                  <Icon
                    sx={{
                      width: "100px",
                      height: "80px",
                      ml: 2,
                    }}
                  >
                    <img
                      src={CreditLogo}
                      alt="Logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Icon>
                </Box>
                <Box display="flex" justifyContent="end">
                  <Box sx={{ marginRight: "2rem" }}>
                    <StyledButton
                      variant="contained"
                      onClick={() => props.setTabIndex((prev) => prev - 1)}
                      bgColor="#F5F8FF"
                      hoverColor="#F5F8FF"
                      textColor="#3075FF"
                      textHoverColor="#3075FF"
                    >
                      <Typography> Previous</Typography>
                    </StyledButton>
                  </Box>
                  <StyledButton
                    variant="contained"
                    type="submit"
                    disabled={
                      !bankDetails?.account_number && !(isSelected === 2)
                    }
                  >
                    <Typography>Continue</Typography>
                  </StyledButton>
                </Box>
              </Box>
            </CustomBox>
          </Box>
        </form>
      )}
    </>
  );
};

export default PreAuthorizedDebit;