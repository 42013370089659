import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";
interface BNPL_MERCHANT_ADDRESS {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}
interface ApplicantAddress{
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}
export interface ICreditCard {
  id: number,
  brand: string,
  loan_application_id: string;
  cardholder: string;
  number: string;
  cvv: string;
  expiry_month: string;
  expiry_year: string;
  address: ApplicantAddress
}
interface BNPL {
  loading: boolean;
  hasError: boolean;
  error: string;
  referalCode: string;
  merchant: {
    fullName: string;
    address: BNPL_MERCHANT_ADDRESS;
  };
  creditCards: ICreditCard[];
}
const initialState: BNPL = {
  loading: false,
  hasError: false,
  error: "",
  referalCode: "",
  merchant: {
    fullName: "",
    address: {
      line1: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
    },
  },
  creditCards: [],
};
// Defining Thunks here
export const getMerchantInformation = createAsyncThunk(
  "bnpl/merchant-information/get",
  async (referralCode: string, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.get(
        `/api/users?referral_key=${referralCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  }
);
export const addCreditCard = createAsyncThunk(
  "applicant-credit-card/post",
  async (data: ICreditCard, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.post(`/api/credit_cards`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.message || "Something went wrong");
    }
  }
);
export const editCreditCard = createAsyncThunk(
  "applicant-credit-card/put",
  async (data: ICreditCard, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.put(`/api/credit_cards/${data?.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.message || "Something went wrong");
    }
  }
);
export const deleteCreditCard = createAsyncThunk(
  "applicant-credit-card/delete",
  async (data: number, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.delete(`/api/credit_cards/${data}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem(`card_${data}`);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.message || "Something went wrong");
    }
  }
);
const bnplSlice = createSlice({
  name: "bnpl",
  initialState,
  reducers: {
    setReferalCode: (state, action) => {
      return { ...state, referalCode: action.payload };
    },
    resetBNPLState: () => {
      return initialState;
    },
    resetCreditCards: (state) => {
      return {...state, creditCards: []}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMerchantInformation.pending, (state, action) => {
      return { ...state, loading: true };
    });
    builder.addCase(getMerchantInformation.fulfilled, (state, action) => {
      return { ...state, merchant: action.payload.merchant, loading: false };
    });
    builder.addCase(getMerchantInformation.rejected, (state, action: any) => {
      return {
        ...state,
        hasError: true,
        error: action.payload.data,
        loading: false,
      };
    });
    builder.addCase(addCreditCard.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(addCreditCard.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      creditCards: [...state.creditCards, action.payload.credit_card]
    }));
    builder.addCase(addCreditCard.rejected, (state, action) => ({
      ...state,
      loading: false,
      hasError: true,
      error: "Something went wrong",
    }));
    builder.addCase(editCreditCard.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(editCreditCard.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      creditCards: state.creditCards.map((card:ICreditCard)=>{
        if(card.id === action.payload.credit_card.id){
          return action.payload.credit_card
        }return card
      }),
    }));
    builder.addCase(editCreditCard.rejected, (state, action) => ({
      ...state,
      loading: false,
      hasError: true,
      error: "Something went wrong",
    }));
    builder.addCase(deleteCreditCard.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(deleteCreditCard.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      creditCards: state.creditCards.filter((card)=>card.id !== action.payload.credit_card.id)
    }));
    builder.addCase(deleteCreditCard.rejected, (state, action) => ({
      ...state,
      loading: false,
      hasError: true,
      error: "Something went wrong",
    }));
  },
});
export const {
  setReferalCode,
  resetBNPLState,
  resetCreditCards
} = bnplSlice.actions;
export default bnplSlice.reducer;