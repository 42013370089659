import * as Yup from "yup";

const ApplicantFormSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email!"),
  phoneNumber: Yup.string()
    .required("Please enter your phone number!")
    .matches(/^\d{3}-\d{3}-\d{4}$/, "Incorrect format"),
  firstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your first name!"),
  lastName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your last name!"),
  dob: Yup.date()
    .max(
      new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000),
      "You must be 18 years or older to apply."
    )
    .required("Please enter your date of birth!"),
  address1: Yup.string().required("Please enter your address!"),
  address2: Yup.string(),
  postalCode: Yup.string()
    .matches(/^[a-zA-Z0-9]{3} [a-zA-Z0-9]{3}$/, "Invalid Postal Code Format")
    .required("Please enter your postal code!"),
  city: Yup.string().required("Please enter your city!"),
  province: Yup.string().required("Please enter your province!"),
  country: Yup.string().required("Please enter your country!"),
});

export default ApplicantFormSchema;
