import React, { ReactElement } from "react";
import { Button, ButtonPropsVariantOverrides } from "@mui/material";
// eslint-disable-next-line import/no-unresolved
import { OverridableStringUnion } from "@mui/types";
import { styled } from "@mui/material/styles";

type ButtonProps = {
  type?: "button" | "submit";
  variant: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  bgColor?: string;
  textColor?: string;
  hoverColor?: string;
  textHoverColor?: string;
};

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 28,
}));

export default function StyledButton({
  type = "button",
  variant,
  children,
  onClick,
  disabled,
  bgColor,
  textColor,
  hoverColor,
  textHoverColor,
}: ButtonProps): ReactElement {
  return (
    <>
      {" "}
      <CustomButton
        variant={variant}
        type={type}
        onClick={onClick}
        disabled={disabled}
        sx={{
          color: textColor || "#fff",
          px: 4,
          textTransform: "capitalize",
          borderRadius: 28,
          backgroundColor: bgColor || "#3075FF",
          "&:disabled": {
            backgroundColor: "#6E9FFF",
            color: textColor || "#fff",
          },
          "&:hover": {
            backgroundColor: hoverColor || "#1b55f5",
            color: textHoverColor || "#fff",
          },
        }}
      >
        {children}
      </CustomButton>
    </>
  );
}
