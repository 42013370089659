import * as Yup from "yup";

const SignupFormSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, and one special character"
    )
    .required("Please enter your password!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
  termsAndConditions: Yup.boolean().oneOf([true]),
});

export default SignupFormSchema;
