import { Typography } from "@mui/material";
const Section3 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 3. Fees
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Loan setup Fees: $0 <br />
        Non-sufficient funds (NSF) Fee: $45 <br />
        Other late or missed payment penalty or interest: $0 <br />
        Other recurring fees: $0
      </Typography>
      <Typography sx={{ mb: 2 }}>
        ONTAB Inc. reserves the right to update fees or charge additional fees.
        We will notify you of the updated changes by sending you an update to
        your listed address at least 60 days in advance.
      </Typography>
    </div>
  );
};

export default Section3;
