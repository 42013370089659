import React, { ReactElement, useMemo, useEffect } from "react";
import LoanInformation from "./LoanInformation";
import Referal from "../Referal";
import ApplicantInformation from "./ApplicantInformation";
import CreditCardInformation from "./CreditCardInformation";
import DebitFormInformation from "./DebitFormInformation";
import ReviewAndSubmit from "./bnpl/ReviewAndSubmit";
import useReferal from "../../hooks/useReferal";
import CreditCardAuthorization from "./bnpl/CreditCardAuthorization";
import LoanInformationBNPL from "./bnpl/LoanInformation";
import ApplicantInformationBNPL from "./bnpl/ApplicantInformation";
import PreAuthorizedDebit from "./bnpl/PreAuthorizedDebit";
import { styled } from "@mui/material/styles";
import { Box, Tabs, Tab } from "@mui/material";
import { BNPL_HEADER_TABS, HEADER_TABS } from "../../constants";
import { getReferalCode, getRequestedAmount } from "../../utils";
import { useAppDispatch } from "../../Redux/store";
import { clearStore } from "../../Redux/features/Applicant";
import { resetBNPLState } from "../../Redux/features/bnpl";

// Defining Custom Style for Tabs & Tab component
const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "#FBFCFF",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-evenly",
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.grey[300]}`,
  textTransform: "none",
}));

// Defining PropTypes for TabPanel
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Main(): ReactElement {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(
    () => {
      // return parseInt(sessionStorage?.getItem("tabValue") || "0")
      return 0;
    }
  );
  const [sliderValue, setSliderValue] = React.useState<number>(100);
  const [sliderValueBNPL, setSliderValueBNPL] = React.useState<number>(()=>getRequestedAmount());
  const [selected, setSelected] = React.useState<number>(-1);
  const containsReferalCode = useReferal();
  const referalCode = getReferalCode();

  // Tab columns
  const headerColumns: string[] = useMemo(() => {
    return referalCode ? BNPL_HEADER_TABS : HEADER_TABS;
  }, [referalCode]);

  // tab change handler
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (referalCode) return; // simply  return in case of bnpl, because we have proper flow to control tab switching
    else {
      // normal application flow
      if (
        ((value === 0 && newValue === 1) || (value === 1 && newValue === 0)) &&
        sliderValue > 100 &&
        selected !== -1
      ) {
        setValue(newValue);
      } else if ((value === 2 && newValue === 1)) {
        setValue(newValue);
      } else if (
        (value === 2 && newValue === 2) ||
        (value === 1 && newValue === 0)
      ) {
        setValue(newValue);
      } else if (
        newValue === 3 && value ===3
      ) {
        setValue(newValue);
      }
    }
  };

  useEffect(()=>{
    dispatch(clearStore());
    dispatch(resetBNPLState());
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("referalCode", JSON.stringify(referalCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ width: "100%", textAlign: "center", flex: 1 }}>
      {containsReferalCode ? (
        <Referal />
      ) : (
        <>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
          >
            {headerColumns.map((tab, index) => (
              <StyledTab
                key={tab}
                label={tab}
                sx={{
                  borderBottom: `${value > index ? "2px solid #0066cc" : ""}`,
                }}
              />
            ))}
          </StyledTabs>
          {referalCode ? (
            <>
              {/* BNPL TABS HERE */}
              <TabPanel value={value} index={0}>
                <LoanInformationBNPL
                  setTabIndex={setValue}
                  sliderValue={sliderValueBNPL}
                  selected={selected}
                  setSliderValue={setSliderValueBNPL}
                  setSelected={setSelected}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ApplicantInformationBNPL setTabIndex={setValue} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CreditCardAuthorization setTabIndex={setValue} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <PreAuthorizedDebit setTabIndex={setValue} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ReviewAndSubmit setTabIndex={setValue} />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <LoanInformation
                  setTabIndex={setValue}
                  sliderValue={sliderValue}
                  selected={selected}
                  setSliderValue={setSliderValue}
                  setSelected={setSelected}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ApplicantInformation setTabIndex={setValue} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CreditCardInformation
                  setTabIndex={setValue}
                  provider="Provider"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <DebitFormInformation setTabIndex={setValue} />
              </TabPanel>
            </>
          )}
        </>
      )}
    </Box>
  );
}
