import { useRef, useState, useEffect } from "react";
import { useMemo } from "react";
import { Box, Typography, Grid, Button, Icon, Divider } from "@mui/material";
import { Checkbox, StyledButton, Box as CustomBox } from "../../../Custom";
import { useFormik } from "formik";
import { ErrorAnimation } from "../../../../animation";
import { ReviewAndSubmitSchema } from "../../../../schemas";
import { CreditLogo } from "../../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../../Redux/store";
import {
  formatCard,
  generatePaymentSchedule,
  getApr,
  getCaculatedAmount,
  getCardHoldAmount,
  getInterestCharges,
  getMonthName,
  getNumberOfPayments,
  getRequestedAmount,
  getTotalCost,
} from "../../../../utils";
import { toast } from "react-toastify";
import {
  getBankDetails,
  submitDebitFormDetails,
} from "../../../../Redux/features/Applicant";
import RSDetails from "./RSDetails";
import Agreement from "../../../Referal/Agreement";
import SignatureCanvas from "react-signature-canvas";
import SignPad from "../../DebitFormInformation/SignatureCanvas";
import theme from "../../../../Theme";
import AlertDialogSlide from "../../DebitFormInformation/Modal";
import ApplicationSubmission from "../../ApplicationSubmission";
import { ICreditCard, resetBNPLState } from "../../../../Redux/features/bnpl";
import "./index.css";
import numeral from "numeral";
import { grey } from "@mui/material/colors";

type ReviewAndSubmitProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

type FormikValues = {
  signature: "";
  confirmAgreement: boolean;
};

const ReviewAndSubmit = (props: ReviewAndSubmitProps) => {
  const initialValues: FormikValues = useMemo(
    () => ({ signature: "", confirmAgreement: false }),
    []
  );

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [payload, setPayload] = useState<any>(null);
  // const [detailsDialog, setDetailsDialog] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [agree, setAgree] = useState(false);
  const [totalAmount,setTotalAmount] = useState("");
  const [openUI, setOpen] = useState(false);
  const credit_source = sessionStorage.getItem("credit_source");

  const [bankDetails, setBankDetails] = useState({
    institution_number: "",
    transit_number: "",
    account_number: "",
    account_type: "",
  });

  const handleSubmissionDialog = () => {
    setSubmitDialog((prev) => !prev);
  };

  // Fetch Banking Information
  useEffect(() => {
    if (sessionStorage?.getItem("AccessToken")) {
      dispatch(getBankDetails([]))
        .unwrap()
        .then((response: any) => {
          setBankDetails(response.bank);
        })
        .catch((err: any) => {
          toast.error(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage?.getItem("AccessToken")]);

  const dispatch = useAppDispatch();

  useEffect(()=>
  {
    setTotalAmount(localStorage.getItem("amount")||"");
  },[])

  const creditCards = useAppSelector((state) => state.bnpl.creditCards);

  const formik = useFormik({
    initialValues,
    validationSchema: ReviewAndSubmitSchema,
    onSubmit: (values, action) => {
      if (
        (bankDetails?.account_number &&
          bankDetails?.transit_number &&
          bankDetails?.institution_number) ||
        credit_source === "card"
      ) {
        const payload: any = {
          institution_number: bankDetails.institution_number,
          transit_number: bankDetails.transit_number,
          account_type: "checking",
          account_number: bankDetails.account_number.replace(/_/g, ""),
          loan_application_id: sessionStorage.getItem("loanApplicationId"),
          access_token: sessionStorage.getItem("AccessToken"),
          pad_signature: values.signature,
          pad_signed_at: new Date().toISOString(),
          application_type: "merchant",
          credit_source: sessionStorage.getItem("credit_source") || "bank",
          credit_holds: creditCards?.map((card: ICreditCard) => {
            return {
              credit_card_id: card?.id,
              hold_amount: getCardHoldAmount(card?.id) || parseInt(totalAmount),
              is_primary: card?.id == parseInt(selectedCardId || ""),
            };
          }),
        };
        setShouldSubmit(true); // Set the flag to indicate that submission should proceed
        setOpen(true);
        setPayload(payload); // Set the payload in state
      } else {
        toast.error("Please choose payment option");
      }
    },
  });

  useEffect(() => {
    if (shouldSubmit && agree && payload) {
      const submitForm = async () => {
        try {
          await dispatch(submitDebitFormDetails(payload)).unwrap();
          handleSubmissionDialog();
          sessionStorage.removeItem("AccessToken");
          sessionStorage.removeItem("LinkToken");
          sessionStorage.removeItem("Address");
          dispatch(resetBNPLState());
          toast.success("Submitted Details");
        } catch (err: any) {
          toast.error(err);
        } finally {
          setAgree(false);
          setShouldSubmit(false); // Reset the flag after submission
        }
      };
      submitForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit, agree, payload]);

  const [signaturePad, setSignaturePad] = useState(false);
  const padRef = useRef<SignatureCanvas>(null);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;

  // Handler to clear signature
  const clearSignature = () => {
    formik.setFieldValue("signature", "");
    formik.setFieldTouched("signature", false);
    padRef?.current?.clear();
  };

  // handler for signature
  const handleSignature = () => {
    setSignaturePad((prev) => !prev);
  };

  const selectedCardId = localStorage.getItem("mainAccountId");

  const creditCard = useAppSelector((state) =>
    state.bnpl.creditCards.filter((card: any) => card.id == selectedCardId)
  )[0];

  // console.log(creditCard, selectedCardId == creditCard[0].id);

  const merchant = useAppSelector((state) => state.bnpl.merchant);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
          <Typography
            sx={{
              color: "#050C19",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "32px",
            }}
            textAlign="center"
          >
            Review and Submit Application
          </Typography>
          {/* Pre Authorized Debit Details */}
          {credit_source === "card" ? (
            <>
            <Box sx={{ my: 4 }}>
                <Typography sx={{ color: "#3075FF" }}>
                  Payment Method
                </Typography>
                <RSDetails
                  title="Credit Card"
                  description={`${
                    creditCard?.number
                      ? formatCard(creditCard?.number)
                      : "**** **** **** 0000"
                  }`}
                />
                <RSDetails title="Card Type" description={creditCard?.brand} />
                <RSDetails
                  title="Cardholder Name"
                  description={`${creditCard?.cardholder || "John Doe"}`}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ my: 4 }}>
                <Typography sx={{ color: "#3075FF" }}>
                  Payment Method
                </Typography>
                <RSDetails
                  title="Bank Institute Number"
                  description={`${bankDetails?.institution_number || "000"}`}
                />
                <RSDetails
                  title="Bank Transit Number"
                  description={`${bankDetails?.transit_number || "000"}`}
                />
                <RSDetails
                  title="Account Number"
                  description={`${bankDetails?.account_number || "00000000"}`}
                />
                <RSDetails
                  title="Account Type"
                  description={bankDetails?.account_type}
                />
              </Box>
            </>
          )}

          {/* Payment Details */}
          <Box sx={{ my: 4 }}>
            <Typography sx={{ color: "#3075FF" }}>Payment Details</Typography>
            <RSDetails title="Interval" description="Monthly" />
            <RSDetails
              title="Amount"
              description={
                `$ ${numeral(getCaculatedAmount()).format("0,00.00")}` ||
                "$100.00"
              }
            />
            <RSDetails
              title="Financed Amount"
              description={`$ ${numeral(getRequestedAmount()).format(
                "0,0.00"
              )}`}
            />
            <RSDetails
              title="Number Of Payments"
              description={getNumberOfPayments().toString()}
            />
            <RSDetails title="APR" description={`${getApr()}%`} />
            <RSDetails
              title="Total Interest Charges"
              description={`$ ${numeral(getInterestCharges()).format(
                "0,0.00"
              )}`}
            />
            <RSDetails
              title="Total Cost"
              description={`$ ${numeral(getTotalCost()).format("0,0.00")}`}
            />
            <RSDetails
              title="Merchant"
              description={merchant?.full_name || "Ultima Hearing Centre"}
            />
          </Box>

          {/* Payment Schedule */}
          <Box sx={{ my: 4 }}>
            <Typography sx={{ color: "#3075FF" }}>Payment Schedules</Typography>
            {generatePaymentSchedule(getNumberOfPayments()).map(
              (date: Date, index: number) => (
                <RSDetails
                  title={`Payment [${index + 1}] (${getMonthName(
                    date.getMonth()
                  )} ${date.getDate()}, ${date.getFullYear()})`}
                  description={`$ ${numeral(getCaculatedAmount()).format(
                    "0,00.00"
                  )}`}
                />
              )
            )}
          </Box>

          <Typography className="text-base" sx={{ my: 2 }}>
            Terms and Conditions
          </Typography>
          {/* Terms And Conditions */}
          <Grid item xs={12} sx={{ my: 2, height: 300, overflowY: "scroll" }}>
            <Agreement />
          </Grid>
          {/* Submitting Form Section */}
          <Grid
            container
            spacing={2}
            sx={{ padding: "2rem 0 0 0", borderTop: "1px solid #F0F5FF" }}
            direction={{ xs: "column", sm: "row" }}
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <Box
                sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
              >
                <Box sx={{ width: "5%" }}>
                  <Checkbox
                    name="confirmAgreement"
                    value={values.confirmAgreement}
                    onChange={handleChange}
                    hasError={
                      !!(errors?.confirmAgreement && touched?.confirmAgreement)
                    }
                  />
                </Box>
                <Box sx={{ width: "95%" }}>
                  <Typography
                    sx={{
                      ml: 1,
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                    }}
                  >
                    I have read and agreed to the terms and conditions of the
                    Financing Agreement and PAD Agreement.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#373D47",
                }}
              >
                By signing below, you agree to the Terms and Conditions
                mentioned in the Loan agreement.
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} spacing={2}>
            {!signaturePad && (
              <Grid
                item
                xs={12}
                sx={{
                  my: 2,
                  border:
                    errors.signature && touched.signature
                      ? "2px solid #F93A5B"
                      : "none",
                  animation: `${
                    errors.signature && touched.signature ? ErrorAnimation : ""
                  } 0.5s`,
                }}
              >
                <Button
                  fullWidth
                  variant="text"
                  type="submit"
                  color="secondary"
                  sx={{
                    padding: "10px 16px",
                    borderRadius: "5px",
                    textTransform: "none",
                    backgroundColor: theme.palette.background.default,
                  }}
                  onClick={handleSignature}
                >
                  Click here to Sign
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sx={{ my: 2 }}>
              {signaturePad && (
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ mb: 2 }}
                    onClick={clearSignature}
                  >
                    Clear
                  </Button>
                  <Box
                    sx={{
                      borderColor: grey[300],
                      border:
                        errors.signature && touched.signature
                          ? "1px solid #F93A5B"
                          : `2px solid ${grey[300]}}`,
                      borderRadius: "8px",
                    }}
                  >
                    <SignPad
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      validateField={formik.validateField}
                      padRef={padRef}
                    />
                    <input
                      type="hidden"
                      name="signature"
                      value={values.signature}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          {/* Submission */}
          <Divider sx={{ my: 4 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
                flexDirection: "column",
              },
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography>Secured By</Typography>
              <Icon
                sx={{
                  width: "100px",
                  height: "80px",
                  ml: 2,
                }}
              >
                <img
                  src={CreditLogo}
                  alt="Logo"
                  style={{ width: "100%", height: "100%" }}
                />
              </Icon>
            </Box>
            <Box display="flex" justifyContent="end">
              <Box sx={{ marginRight: "2rem" }}>
                <StyledButton
                  variant="contained"
                  onClick={() => props.setTabIndex((prev) => prev - 1)}
                  bgColor="#F5F8FF"
                  hoverColor="#F5F8FF"
                  textColor="#3075FF"
                  textHoverColor="#3075FF"
                >
                  <Typography> Previous</Typography>
                </StyledButton>
              </Box>
              <StyledButton variant="contained" type="submit">
                <Typography>Submit Application</Typography>
              </StyledButton>
            </Box>
          </Box>
          {openUI && <AlertDialogSlide setAgree={setAgree} setOpen={setOpen} />}
          {submitDialog && (
            <ApplicationSubmission
              open={submitDialog}
              close={handleSubmissionDialog}
              setTabIndex={props.setTabIndex}
            />
          )}
        </CustomBox>
      </Box>
    </form>
  );
};

export default ReviewAndSubmit;