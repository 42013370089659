import React from "react";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";

type SignaturePadProps = {
  setFieldValue: (field: string, value: string) => void;
  setFieldTouched: (field: string, value: boolean) => void;
  validateField: (name: string) => void;
  padRef: React.RefObject<SignatureCanvas>;
};

export default function SignPad(props: SignaturePadProps) {
  return (
    <SignaturePad
      ref={props.padRef}
      canvasProps={{
        style: {
          width: "100%",
          height: "100%",
          padding: "0.5rem",
        },
      }}
      onEnd={() => {
        const url = props.padRef.current
          ?.getTrimmedCanvas()
          .toDataURL("image/png");
        if (url) {
          props.setFieldValue("signature", url);
          props.setFieldTouched("signature", false);
          props.validateField("signature");
        }
      }}
    />
  );
}
