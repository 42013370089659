import { Typography } from "@mui/material";
// import { useAppSelector } from "../../../Redux/store";

const Section1 = () => {
  // const applicantData = useAppSelector((state: any) => state.applicant.data);
  // const personData = applicantData?.attributes;
  // const homeAddress = personData?.homeAddress?.data?.attributes;
  // const requestedLoanAmount = getRequestedAmount();
  // const financedAmount = getCaculatedAmount();
  // const noPayments = getNumberOfPayments();
  return (
    <div className="section">
      <Typography variant="h5">Section 1: Credit Details</Typography>
      <ol className="agreement-list">
        <li>
          <Typography className="agreement-list-heading">
          Personal Credit Agreement:
          </Typography>
          <Typography className="list-item">
            This Loan Agreement is created between “You”, the Borrower and “Us”,
            “ONTAB”, the Lender
          </Typography>
          <Typography className="list-item" sx={{ fontWeight: "bold" }}>
            {/* {personData?.fullName || "ONTAB Inc."} <br />
            {homeAddress?.line1 || "10 Dundas St E"} <br />
            {homeAddress?.city && homeAddress?.zipcode
              ? `${homeAddress.city}, ${homeAddress.zipcode}`
              : "Toronto, ON M5B 2G9"} */}
              ONTAB Inc.
              <br />
              10 Dundas St E
              <br />
              Toronto, ON M5B 2G9
          </Typography>
          <Typography className="list-item">
            You hereby authorize ONTAB to collect and store Personal Information
            in order to process the Loan Amount as listed under{" "}
            <i>Section 1, subsection a. Loan details.</i>
          </Typography>
          <Typography>
            <ol className="agreement-list-alpha">
              <li>
                <Typography className="agreement-list-heading">
                Credit Details:
                </Typography>
                <Typography className="list-item">
                You further authorize ONTAB to pay the requested loan amount to the mentioned merchant 
                and place an Authorization on your Credit Card for an amount equivalent to your credit Amount. 
                The Authorization will renew regularly and be reduced by the Payment Amount made towards the 
                Loan Amount. It is your responsibility to ensure that the most accurate and up to date 
                Credit Card information is provided and maintained on file as outlined in
                  <i>
                    Section 1, subsection VII. Authorization Hold on Credit
                    Card.
                  </i>
                </Typography>
              </li>
              <li>
                <Typography className="agreement-list-heading">
                  Delays in Loan Disbursement:
                </Typography>
                <Typography className="list-item">
                ONTAB cannot be held liable for delays in the disbursement of the Loan Amount 
                to the merchant which may occur due to factors beyond our control; including, 
                but not limited to, delays by the Issuing or Sponsoring Banks and/or Payment Processors, 
                as well as any Unforeseeable Events affecting any aspect of the disbursement process.
                </Typography>
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">
            Missed Payment and Penalties:
          </Typography>
          <Typography className="list-item">
            In case of Missed Payments, ONTAB is authorized to charge a penalty,
            as listed under <i>Section 3. Fees.</i>
          </Typography>
          <Typography>
            <ol className="agreement-list-alpha">
              <li>
                <Typography className="agreement-list-heading">
                  Interest on Missed Payment or Partial Payment:
                </Typography>
                <Typography className="list-item">
                  If a Payment Amount is missed or partially paid, we will charge the loan 
                  interest only to the outstanding Payment Amount. This Outstanding Balance 
                  will accrue interest until fully paid off. Missed Payments or Partial 
                  Payments may cause changes to the Payment Schedule as listed above.
                </Typography>
              </li>
              <li>
                <Typography className="agreement-list-heading">
                  Non-sufficient Funds (NSF):
                </Typography>
                <Typography className="list-item">
                  If a Missed Payment creates a Non-sufficient Fund, an NSF fee will be 
                  charged. This fee will accumulate until paid off. Failure to pay any 
                  and all outstanding fees will result in the loan not being discharged.
                </Typography>
              </li>
              <li>
                <Typography className="agreement-list-heading">
                  Consecutive Missed Payments:
                </Typography>
                <Typography className="list-item">
                  If two (2) or more missed payments occur consecutively, ONTAB may 
                  choose to cancel the Loan and withdraw the total Outstanding Balance 
                  by capturing the Authorized amount on your Credit Card.
                </Typography>
              </li>
            </ol>
          </Typography>
        </li>
        <Typography sx={{ mb: 2 }}>
          ONTAB reserves the right to modify Fees or to institute new Fees at any 
          time with 30 days' notice on our Website and 60 days’ notice in writing.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          I understand that ONTAB Inc. is authorized to withdraw money from my
          credit card account 15 days after a missed payment if no satisfactory
          payment arrangement has been put in place.
        </Typography>

        <li>
          <Typography className="agreement-list-heading">
            Prepayment:
          </Typography>
          <Typography className="list-item">
            There are no fees or penalties for Prepayment towards the credit.
            Any Prepayment Amounts will be paid towards outstanding Interest and
            Fees first and then applied to subsequent Principal amounts.
          </Typography>
          <Typography className="list-item">
            For all Prepayments towards the Loan Amount, no Interest will be
            charged if the payment is made before the Payment Due Date.
          </Typography>
          <Typography className="list-item">
            All Prepayments to the Principal will be applied to the last
            scheduled Payment Amount first and then preceding Payment Amounts.
            This will, in effect, maintain the scheduled monthly Payment Amounts
            but reduce the Payment Term.
          </Typography>
          <Typography className="list-item">
            Prepayments may cause changes to the Payment Schedule as listed
            above.
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">Refunds:</Typography>
          <Typography className="list-item">
            All refunds need to be initiated by the merchant. Refunds are 
            processed based on the status of your payments. 
          </Typography>
          <Typography>
            <ul style={{ listStylePosition: "inside", padding: '1rem 2rem', listStyleType: "disc" }}>
              <li className="refunds-list-item">
                If a refund is initiated before the first payment is collected (within 30 days) 
                the refund amount will be collected directly from the merchant.
              </li>
              <li className="refunds-list-item">
                If a refund is initiated after 30 and before 60 days and a payment 
                has been made, only the principal portion of the payments made 
                would be refunded to the payment method selected. Interest portion 
                of the monthly payments is non-refundable.
              </li>
              <li className="refunds-list-item">
                Refund requests after 60 days are subject to approval by ONTAB.
              </li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">
            Authorization Hold on Credit Card:
          </Typography>
          <Typography className="list-item">
            Unless otherwise stated, ONTAB will place a hold on the Credit Card. 
            This Authorization Hold will equal the Loan Amount applied to the Credit Card. 
            You are not permitted to cancel your Credit Card without obtaining prior 
            consent from ONTAB or informing ONTAB immediately after, for as 
            long as ONTAB has a Hold/Pre-authorisation in place.
          </Typography>
          <Typography className="list-item">
            This Authorisation Hold will be reduced within 14 days with
            subsequent scheduled payments, in amounts equal to the Principal
            portion of monthly Payment Amounts.
          </Typography>
          <Typography className="list-item">
            It is your responsibility to notify ONTAB of any changes to 
            the Credit Card on file, or issues that may prevent ONTAB 
            from placing a Hold/Authorisation on the Credit Card. This includes, 
            but is not limited to, situations where you have reported your 
            Credit Card as lost, stolen, or compromised to your Issuing 
            Bank; or where the Credit Card Issuing Bank has flagged your 
            Credit Card for any reason; or where the Credit Card has 
            Expired or has been Canceled by the Issuing Bank.
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">
            Applicable Law:
          </Typography>
          <Typography className="list-item">
            This Agreement will be governed by and construed in accordance with 
            the laws of the Province of Ontario and the laws of Canada applicable 
            in that Province and will be treated, in all respects, as an Ontario contract.
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">
            Loan Default or Delinquency:
          </Typography>
          <Typography className="list-item">
            In case of a Loan Default or Delinquency, ONTAB is authorized 
            to charge the Total Outstanding Amount to the Credit Card, 
            report the Total Outstanding Amount and Payment History on your 
            Credit Report with any Credit Bureau in Canada, and/or pursue 
            any authorized collection activities.
          </Typography>
        </li>
        <li>
          <Typography className="agreement-list-heading">
            Consent to Agreement and Declaration:
          </Typography>
          <Typography className="list-item">
            I/We hereby confirm that the information provided herein is accurate, 
            correct and complete and that the documents submitted 
            along with this application form are genuine. 
          </Typography>
        </li>
        <Typography sx={{ mb: 2 }}>
          If any information provided is deemed to be false, inaccurate or
          incomplete; all Agreements are immediately deemed Null and Void and
          the entire Loan Amount becomes payable to ONTAB. ONTAB may, in these
          circumstances, recover the funds through any legal means necessary,
          including forced charges on the Credit Card on file.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          I undertake to inform ONTAB Inc. of any changes to the information
          already provided and to update the information on this form whenever
          requested to do so on the platform, by email, telephone or in writing.
        </Typography>
      </ol>
    </div>
  );
};

export default Section1;
