import { FC, ReactElement, forwardRef, Ref } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  Box,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import theme from "../../../Theme";
import { CloseIcon } from "../../../assets/svg";
import { AccountInfoCheck } from "../../../assets/png";

type DebitDetailsProps = {
  open: boolean;
  close: () => void;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const index: FC<DebitDetailsProps> = (
  props: DebitDetailsProps
): ReactElement => (
  <div>
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.close}
      aria-describedby="pre-authorized debit-details"
      PaperProps={{
        sx: {
          maxWidth: "64%",
          padding: 4,
          backgroundColor: "white",
        },
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: theme.palette.background.paper },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">How to find PAD Details</Typography>
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 0,
          }}
          onClick={props.close}
        >
          <img
            src={CloseIcon}
            alt="close-icon"
            style={{ width: "100%", height: "100%" }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", height: "25%" }}>
          <img
            src={AccountInfoCheck}
            alt="close-icon"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <DialogContentText
          id="pre-authorized debit-details"
          sx={{ color: "inherit" }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </DialogContentText>
      </DialogContent>
    </Dialog>
  </div>
);

export default index;
