import { useCallback } from "react";
import { allCountries, CountryData, Region } from "country-region-data";

const useProvinces = (country: string) => {
  const findProvinces = () => {
    const choosedCountry = allCountries.filter(
      (c: CountryData) => c[0] === country
    );
    if (choosedCountry.length > 0) {
      if (choosedCountry.length > 0) {
        const valuesToRemove = ["Quebec", "Saskatchewan", "New Brunswick"];
        const province = choosedCountry[0][2].map(
          (region: Region) => region[0]
        );
        return province.filter(
          (value: string) => !valuesToRemove.includes(value)
        );
      }
    }
    return [];
  };

  // memoize provinces and countries
  const memoizedProvinces = useCallback(findProvinces, [country]);
  return memoizedProvinces;
};

export default useProvinces;
