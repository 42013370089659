import  { FC, ReactElement, useState } from "react";
import { Typography, TextField, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ErrorAnimation } from "../../../animation";
import theme from "../../../Theme";

// Defining Input Field Props
type InputFieldProps = {
  label: string;
  placeholder: string;
  name: string;
  dateField?: boolean | false;
  hasError?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  [key: string]: any;
};

const CustomInputField: FC<InputFieldProps> = (
  props: InputFieldProps
): ReactElement => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  return (
    <>
      <Typography
        sx={{
          mb: 1,
          visibility: `${props.label === "" ? "hidden" : "block"}`,
        }}
      >
        {props.label}
        <Typography sx={{ color: "error.main" }} component="span">
          {props.isRequired && " *"}
        </Typography>
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        inputProps={{ max: "9999-12-31" }}
        InputProps={{
          notched: false,
          readOnly: props.name === "interval",
          sx: {
            backgroundColor: "ghostwhite",
          },
          endAdornment:
            props.name === "password" || props.name === "confirmPassword" ? (
              <InputAdornment
                position="end"
                onClick={() => setPasswordVisible((prev) => !prev)}
              >
                {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </InputAdornment>
            ) : null,
        }}
        InputLabelProps={{ shrink: false }}
        sx={{
          padding: "2px 2px",
          color: theme.palette.secondary.main,
          animation: `${props.hasError ? ErrorAnimation : ""} 0.5s`,
          "& .MuiInputBase-root": {
            backgroundColor: props.hasError ? "#fff" : "ghostwhite",
          },
          "& .MuiFormHelperText-root": {
            color: "error.main",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: `${props.hasError ? "error.main" : "ghostwhite"}`,
            },
          },
        }}
        placeholder={props.placeholder}
        onChange={props?.onChange}
        name={props.name}
        helperText={props.helperText}
        type={
          (props.name === "password" || props.name === "confirmPassword") &&
          !passwordVisible
            ? "password"
            : props.dateField
            ? "date"
            : "text"
        }
        value={
          props.dateField && props.value instanceof Date
            ? props.value.toISOString().slice(0, 10)
            : props.value
        }
        disabled={props.isDisabled || false}
      />
    </>
  );
};

export default CustomInputField;
