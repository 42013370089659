import * as Yup from "yup";

const DebitFormSchema = Yup.object({
  bankNumber: Yup.string()
    .min(3)
    .max(3)
    .required("Please enter your bank number!"),
  accountType: Yup.string().required("Please enter your account type!"),
  accountNumber: Yup.string()
    .max(12)
    .required("Please enter your account number!"),
  transitNumber: Yup.string()
    .min(5)
    .max(5)
    .required("Please enter your transit number!"),
  jointAccount: Yup.boolean(),
  signature: Yup.string().required("Please enter your signature!"),
  confirmAgreement: Yup.boolean().oneOf([true], "Please confirm the agreement"),
  interval: Yup.string(),
  amount: Yup.number(),
  financedAmount: Yup.number(),
  numberOfPayments: Yup.number(),
  merchantName: Yup.string(),
});

export default DebitFormSchema;
