import { Grid, Typography, Button } from "@mui/material";
import { ReferalImage } from "../../assets/png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getReferalCode } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { getMerchantInformation } from "../../Redux/features/bnpl";
import { toast } from "react-toastify";
import "./index.css";

import theme from "../../Theme";

const Referal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const referralCode: string = getReferalCode();
  const merchant = useAppSelector((state) => state.bnpl.merchant);

  const handleApplyNow = () => navigate("/", {replace: true});

  useEffect(() => {
    // fetch Merchant Information
    dispatch(getMerchantInformation(referralCode))
      .unwrap()
      .catch((error: any) => {
        toast.error(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginBottom: "2rem" }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            textAlign: "left",
            padding: "0 6rem",
            [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
              marginBottom: "1rem",
              padding: "0 2rem",
            },
           
          }}
        >
          <Typography className="referal-text-heading">
            ONTAB Merchant Credit
          </Typography>
          <Typography className="referal-text-base">
            Experience seamless shopping with OnTab Credit, bid farewell to
            upfront payments, and finance your purchase at 0% APR with
            stress-free monthly installments at
          </Typography>
          <Typography variant="body2" className="referal-text-heading">
            {merchant?.full_name || "Ultima Hearing Centre"}
            <br />
            {merchant?.address?.line1 || "5995 14th Avenue"}
            <br />
            {merchant?.city || "Markham"}, {merchant?.state || "ON"} {merchant?.zipcode || "L3S 0A2"}
          </Typography>

          <Button
            variant="contained"
            sx={{ borderRadius: 10, textTransform: "capitalize" }}
            size="large"
            onClick={handleApplyNow}
          >
            <Typography>Apply Now</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="end">
          <img src={ReferalImage} alt="" className="referral-image" />
        </Grid>
      </Grid>
    </>
  );
};

export default Referal;
