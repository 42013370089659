import { Typography } from "@mui/material";
const Section2 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 2: Account Use, Terms and Conditions
      </Typography>
      <Typography sx={{ mb: 2 }}>
        This loan is provided by ONTAB Inc. (“Us”, “We”, “Lender”, “ONTAB”) to you, 
        (“You”, “Borrower”) to help reduce your credit card loan and loan payment 
        amounts. This loan may only be used towards the payment of the credit 
        card as stated in our Agreement. Any misuse of funds or the terms 
        of this agreement may result in the immediate termination of this 
        loan and require the immediate repayment of all outstanding loan amounts.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        ONTAB is authorized to collect any outstanding payments or loan 
        amounts from your credit card in case of missed payments. 
        You may not dispute the charges nor claim chargebacks on these payments. 
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Failure to comply with these terms and conditions may result in the
        termination of the loan and require the immediate repayment of all
        outstanding loan amounts.
      </Typography>
    </div>
  );
};

export default Section2;
