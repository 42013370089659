import { Box, Typography } from "@mui/material";
import {
  PadRequest,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  TermsAndConditions,
} from "./sections";

const Agreement = (props: any) => {
  return (
    <Box sx={{ overflowX: "hidden", color: '#373D47' }}>
      <Typography variant="h3" align="center" sx={{mb: 4}}>
        ONTAB Credit Agreement
      </Typography>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <PadRequest />
      <TermsAndConditions />
    </Box>
  );
};

export default Agreement;
