import { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getReferalCode } from "../../../../../utils";
import {
  calculateDiscountedPerMonthInstallment,
  calculatePerMonthInstallment,
  calculateTotalAmount,
} from "../../../../../Helpers";
import "./index.css";
import theme from "../../../../../Theme";
import numeral from "numeral";
type PaymentCardProps = {
  months: number;
  total: string;
  interest: number;
  selected: number;
  cardKey: number;
  sliderValue: number;
  discountedInterest?: number;
};
export default function PaymentCard(props: PaymentCardProps) {
  const referalCode = getReferalCode();
  const hasDiscount = () => {
    if (referalCode) {
      if (props.discountedInterest === undefined) return false;
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (props.selected === props.cardKey) {
      const months = props.selected;
      // localStorage.setItem("numberOfPayments", months.toString());
      let pmt;
      let totalCost;
      let interestCharges;
      if (hasDiscount()) {
        pmt = calculateDiscountedPerMonthInstallment(props.sliderValue, months);
        totalCost = props.months * (props.sliderValue / props.months);
        interestCharges = totalCost - props.sliderValue;
      } else {
        pmt = calculatePerMonthInstallment(
          props.interest / 1200,
          months,
          props.sliderValue
        );
        totalCost = calculateTotalAmount(
          props.interest / 1200,
          props.months,
          props.sliderValue
        );
        interestCharges = totalCost - props.sliderValue;
      }
      localStorage.setItem("pmt", numeral(pmt).format('0,0.00'));
      localStorage.setItem("totalCost", totalCost.toString());
      localStorage.setItem("interestCharges", interestCharges.toString());
      localStorage.setItem("amount", props.sliderValue.toString());
      // if (hasDiscount() && (props.selected === 6 || props.selected === 3))
      //   localStorage.setItem("apr", "0");
      // else localStorage.setItem("apr", props.interest.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected, props.sliderValue]);
  return (
    <Card
      sx={{
        width: "98%",
        padding: 1,
        backgroundColor:
          props.selected === props.cardKey
            ? "#FBFCFF"
            : theme.palette.background.default,
        border: props.selected === props.cardKey ? "1px solid #007AFF" : "none",
        borderRadius: "8px",
        boxShadow: "0",
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.months} Monthly Payments
        </Typography>
        <Typography
          sx={{ fontSize: 16, color: "black", fontWeight: "bold" }}
          gutterBottom
        >
          {hasDiscount()
            ? `$ ${numeral(
                calculateDiscountedPerMonthInstallment(
                  props.sliderValue,
                  props.months
                )
              ).format("0,0.00")} /month`
            : `$ ${numeral(
                calculatePerMonthInstallment(
                  props.interest / 1200,
                  props.months,
                  props.sliderValue
                )
              ).format("0,0.00")} /month`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {hasDiscount() ? (
            <>
              <span className="discount">
                {props.interest}%
                <span className="discounted">{props.discountedInterest} %</span>
              </span>
              <span>APR*</span>
              <span>
                | Total Cost:{" "}
                <span className="discount">
                  $
                  {numeral(
                    calculateTotalAmount(
                      props.interest / 1200,
                      props.months,
                      props.sliderValue
                    )
                  ).format("0,0.00")}
                  <span className="discounted">
                    {/* {(props.discountedInterest as number) > 0 ? `$${(calculateTotalAmount((props.discountedInterest as number)/1200,props.months,props.sliderValue))}`: '$0'}  */}
                    $
                    {numeral(
                      props.months * (props.sliderValue / props.months)
                    ).format("0,0.00")}
                  </span>
                </span>
              </span>
            </>
          ) : (
            <>
              {props.interest}% APR* | Total Cost: $
              {numeral(
                calculateTotalAmount(
                  props.interest / 1200,
                  props.months,
                  props.sliderValue
                )
              ).format("0,0.00")}
            </>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}