import { Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <div className="section">
      <Typography className="agreement-list-heading">
        Terms and Conditions (PAD)
      </Typography>
      <Typography sx={{ my: 2 }}>
        This Agreement is being entered into for our benefit and the benefit of
        any financial institution that holds the PAD Account (the "PAD
        Institution"), and is being entered into in consideration of the PAD
        Institution agreeing to process.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        In case the payment amount is fixed, you waive any requirement that
        ONTAB give pre-notification of the payment amount except for the first
        amount, where ONTAB will send you a notification 3 days in advance.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        In case the payment amount is variable, ONTAB will give you a
        notification of the payment amount at least 10 days in advance.{" "}
      </Typography>
      <Typography sx={{ mb: 2 }}>
        To cancel or change this PAD Agreement, please resubmit this
        application, notify us in writing to support@ontab.com or call us on our
        Toll free number at least 5 days before your next payment due date.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        PAD changes and cancellations cannot be made within 5 days of your next
        payment due date. Any changes made within the last 5 days before your
        payment due date will only take effect on your next scheduled payment
        due date.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Canceling a pre-authorized debit agreement does not cancel your loan
        agreement or any amount owed. The cancellation applies only to the
        payment method. Therefore please make an alternative payment arrangement
        to avoid late payment fees or loan payment default.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        You have certain recourse rights if any debit does not comply with this
        agreement. For example, you have the right to receive reimbursement for
        any debit that is not authorized or is not consistent with this PAD
        Agreement. To obtain more information on your recourse rights, contact
        your financial institution or visit payments.ca.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        If there are insufficient funds in the account to cover a withdrawal,
        ONTAB is authorized to retry the debit one more time within 30 days from
        the date of the withdrawal for the exact same amount.
      </Typography>
    </div>
  );
};

export default TermsAndConditions;
