import { useEffect, useState } from "react";
import { Box, Typography, Button, Divider, Icon, Grid } from "@mui/material";
import { CreditLogo } from "../../../../assets/svg";
import { StyledButton, Box as CustomBox } from "../../../Custom";
import { useAppSelector } from "../../../../Redux/store";
import CreditCard from "./CreditCardDetails";
import AddACardModal from "./AddACardModal";
import theme from "../../../../Theme";
type CreditCardAuthorizationProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};
export type cardEditDetailsProps = {
  cardHolderName: string;
  number: string;
  isEditing: boolean;
};
const CreditCardAuthorization = (props: CreditCardAuthorizationProps) => {
  const [addCardModal, setAddCardModal] = useState(false);
  const [myTotalSplitAmount, setTotalSplitAmount] = useState(0);
  const [error, setError] = useState(false);
  const [cardEditDetails, setCardEditDetails] =
    useState<cardEditDetailsProps>();
  const creditCards = useAppSelector((state: any) => state.bnpl.creditCards);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (creditCards?.length > 0 && creditCards.length <= 4) {
      // setAddCardState(true);
      props.setTabIndex((prevIndex: number) => prevIndex + 1);
    }
  };
  // function checkSplitAmount() {
  //   const availableAmount = parseFloat(
  //     localStorage.getItem("requestedAmount") || "0"
  //   );
  //   let totalSplitAmount = 0;
  //   let no_of_cards = 0;
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key && key.startsWith("card_")) {
  //       no_of_cards += 1;
  //       const storedValue = JSON.parse(localStorage.getItem(key) || "{}");
  //       if (
  //         storedValue.splitAmount &&
  //         typeof storedValue.splitAmount === "number"
  //       ) {
  //         totalSplitAmount += storedValue.splitAmount;
  //       }
  //     }
  //   }
  //   if (totalSplitAmount == 0 && no_of_cards < 1) {
  //     totalSplitAmount = availableAmount;
  //   }
  //   if (no_of_cards > 1 && totalSplitAmount === availableAmount) {
  //     setError(true);
  //   } else {
  //     setError(false);
  //   }
  //   setTotalSplitAmount(totalSplitAmount);
  //   localStorage.setItem(
  //     "totalSplitAmount",
  //     totalSplitAmount === 0 ? totalSplitAmount.toString() : ""
  //   );
  //   if (
  //     !totalSplitAmount ||
  //     totalSplitAmount > availableAmount ||
  //     totalSplitAmount < availableAmount
  //   ) {
  //     setError(true);
  //   } else {
  //     setError(false);
  //   }
  // }
  function checkSplitAmount() {
    const availableAmount = parseFloat(
      localStorage.getItem("requestedAmount") || "0"
    );
    let totalSplitAmount = 0;
    let no_of_cards = 0;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith("card_")) {
        no_of_cards += 1;
        const storedValue = JSON.parse(localStorage.getItem(key) || "{}");
        if (
          storedValue.splitAmount &&
          typeof storedValue.splitAmount === "number"
        ) {
          totalSplitAmount += storedValue.splitAmount;
        }
      }
    }
    console.log(
      "split amount:",
      totalSplitAmount,
      creditCards.length,
      totalSplitAmount == 0 && creditCards.length == 1
    );
    console.log("available amount:", availableAmount);
    if (totalSplitAmount == 0 && creditCards.length == 1) {
      totalSplitAmount = availableAmount;
    } else if (
      totalSplitAmount > 0 &&
      creditCards.length == 1 &&
      totalSplitAmount != availableAmount
    ) {
      totalSplitAmount = availableAmount;
    }
    // if (no_of_cards > 1 && totalSplitAmount === availableAmount) {
    //   setError(true);
    // } else {
    //   setError(false);
    // }
    console.log("split amount after:", totalSplitAmount);
    setTotalSplitAmount(totalSplitAmount);
    localStorage.setItem(
      "totalSplitAmount",
      totalSplitAmount ? totalSplitAmount.toString() : "0"
    );
    if (
      !totalSplitAmount ||
      totalSplitAmount > availableAmount ||
      totalSplitAmount < availableAmount
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }
  const handleSplitChange = () => {
    checkSplitAmount();
  };
  useEffect(() => {
    checkSplitAmount();
  }, [myTotalSplitAmount, creditCards.length]);
  // useEffect(() => {}, [error]);
  return (
    <>
      {addCardModal ? (
        <>
          <AddACardModal
            setTabIndex={props.setTabIndex}
            addACardModal={addCardModal}
            handleClose={() => setAddCardModal(false)}
            cardEditDetails={cardEditDetails}
            setCardEditDetails={setCardEditDetails}
          />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Credit Card Information
              </Typography>
              <Typography
                sx={{
                  color: "secondary.main",
                  fontSize: "15px",
                  my: 2,
                  letterSpacing: 0.5,
                }}
              >
                Please use a credit card with a limit equal to or higher than
                the requested facility. We'll authorize your card and reduce the
                amount as you make payments. You can split the authorization
                across up to 4 Visa or Mastercard credit cards to cover your
                loan amount.
              </Typography>
              {/* Display Added Cards */}
              {creditCards.length <= 0 ? (
                <>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => setAddCardModal((prev) => !prev)}
                      sx={{
                        backgroundColor: "ghostwhite",
                        color: "#3075FF",
                        textTransform: "capitalize",
                        borderRadius: "20px",
                        width: "200px",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor: "ghostwhite",
                          color: "#3075FF",
                        },
                      }}
                      type="submit"
                    >
                      <Typography> Add A Card</Typography>
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <>
                    <Grid container spacing={2} sx={{ mt: 4 }}>
                      {creditCards
                        .slice(0, 4)
                        .map((card: any, index: number) => (
                          <Grid item key={index}>
                            <CreditCard
                              handleSplitChange={handleSplitChange}
                              card={card}
                              toggleModal={() =>
                                setAddCardModal((prev) => !prev)
                              }
                              setCardEditDetails={setCardEditDetails}
                              preData={creditCards?.length === 1 ? false : true}
                            />
                          </Grid>
                        ))}
                    </Grid>
                    {creditCards.length == 4 && (
                      <Typography
                        sx={{ textAlign: "center", my: 4, color: "red" }}
                      >
                        Cannot enter more than 4 cards.
                      </Typography>
                    )}
                  </>
                  {error ? (
                    <Typography
                      sx={{ textAlign: "center", my: 4, color: "red" }}
                    >
                      Please ensure that the total of authorized amount matches
                      the loan amount of $
                      {localStorage.getItem("requestedAmount")}.
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Typography sx={{ textAlign: "center", my: 4 }}>
                    Split the authorization on multiple cards:{" "}
                  </Typography>
                  {creditCards.length < 4 && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        onClick={() => setAddCardModal((prev) => !prev)}
                        sx={{
                          backgroundColor: "ghostwhite",
                          color: "#3075FF",
                          textTransform: "capitalize",
                          borderRadius: "20px",
                          width: "250px",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "ghostwhite",
                            color: "#3075FF",
                          },
                        }}
                        type="submit"
                      >
                        <Typography> Add an Additional Card</Typography>
                      </Button>
                    </Box>
                  )}
                </>
              )}
              <Divider sx={{ my: 4 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography>Secured By</Typography>
                  <Icon
                    sx={{
                      width: "100px",
                      height: "80px",
                      ml: 2,
                    }}
                  >
                    <img
                      src={CreditLogo}
                      alt="Logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Icon>
                </Box>
                <Box display="flex" justifyContent="end">
                  <Box sx={{ marginRight: "2rem" }}>
                    <StyledButton
                      variant="contained"
                      onClick={() => props.setTabIndex((prev) => prev - 1)}
                      bgColor="#F5F8FF"
                      hoverColor="#F5F8FF"
                      textColor="#3075FF"
                      textHoverColor="#3075FF"
                    >
                      <Typography> Previous</Typography>
                    </StyledButton>
                  </Box>
                  <StyledButton
                    variant="contained"
                    type="submit"
                    disabled={creditCards.length === 0 || error}
                  >
                    <Typography>Continue</Typography>
                  </StyledButton>
                </Box>
              </Box>
            </CustomBox>
          </Box>
        </form>
      )}
    </>
  );
};
export default CreditCardAuthorization;