import { forwardRef, Ref, ReactElement } from "react";
import {
  Box,
  Typography,
  Grid,
  Icon,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Button,
} from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import theme from "../../../Theme";
import { CheckIcon } from "../../../assets/svg";
import { useAppDispatch } from "../../../Redux/store";
import { clearStore } from "../../../Redux/features/Applicant";
import { getReferalCode } from "../../../utils";
import { StyledButton } from "../../Custom";
import { resetBNPLState } from "../../../Redux/features/bnpl";

type ApplicationSubmissionProps = {
  open: boolean;
  close: () => void;
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);
export default function ApplicationSubmission(
  props: ApplicationSubmissionProps
) {
  const dispatch = useAppDispatch();
  const referralCode = getReferalCode();
  const applicantEmail = localStorage.getItem("email");

  // Modal Handler
  const closeModal = () => {
    // props.close();
    dispatch(clearStore());
    dispatch(resetBNPLState());
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "https://www.ontab.com/credit/";
  };
  return (
    <div>
      <Dialog fullScreen TransitionComponent={Transition} open={props.open}>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{
            backgroundColor: "white",
          }}
        >
          <Toolbar sx={{ ml: "4rem" }}>
            <Button
              variant="text"
              startIcon={<KeyboardArrowLeft sx={{ fontSize: "1.5rem" }} />}
              onClick={closeModal}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                borderRadius: "25px",
                p: "10px 24px",
                textTransform: "capitalize",
              }}
            >
              Go Back
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.background.default,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            py: 20,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              maxWidth: "42%",
              width: "100%",
              p: 5,
              [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {
                maxWidth: "100%",
              },
            }}
          >
            <Icon
              sx={{
                width: "100px",
                height: "100px",
                mx: "10px",
                mt: "0.5rem",
              }}
            >
              <img
                src={CheckIcon}
                alt="Check"
                style={{ width: "100%", height: "100%" }}
              />
            </Icon>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Application Submitted
            </Typography>
            <Typography id="modal-modal-description" sx={{ my: 2 }}>
              {referralCode
                ? `Thank you for submitting your application. A confirmation email will be sent to ${
                    applicantEmail || "user@email.com"
                  }.`
                : "Thank you for your application, we will get in touch with you shortly."}
            </Typography>
            <StyledButton variant="contained" onClick={closeModal}>
              Done
            </StyledButton>
          </Box>
        </Grid>
      </Dialog>
    </div>
  );
}
