import { useSearchParams } from "react-router-dom";
const useReferal = () => {
  const [params] = useSearchParams();
  let referalCode = params.get("referral_code") || "";
  if (referalCode) {
    localStorage.setItem("referalCode", JSON.stringify(referalCode));
  }
  return referalCode;
};
export default useReferal;
