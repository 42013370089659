import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import ApplicantForm from "./Form";
import CustomBox from "../../../Custom/Box";

type ApplicantInformationProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

export default function ApplicantFormInformation(
  props: ApplicantInformationProps
): ReactElement {
  return (
    <Box
      sx={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomBox sm={{ width: "100%" }} md={{ width: "50%" }}>
        <Typography>Applicant Information</Typography>
        <ApplicantForm setTabIndex={props.setTabIndex} />
      </CustomBox>
    </Box>
  );
}
