import { Typography } from "@mui/material";
const Section7 = () => {
  return (
    <div className="section">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Section 7: Pre-authorised Payment Agreement
      </Typography>
      <Typography sx={{ mb: 2 }}>Category: Personal PAD</Typography>
      <Typography sx={{ mb: 2 }}>
        Pay above ONTAB loan payments from your Canadian Credit card, Chequing or Savings
        bank account.
      </Typography>
    </div>
  );
};

export default Section7;
